@import 'styles/variables/mqueries.scss';

#wp-site-menu {
    background-color: #555;
    color: #ddd;
    box-sizing: border-box;
    order: 1;
    flex-shrink: 0;
    transition: margin-left 1s;
    z-index: 40;

    @media #{$all-wide} {
        width: 300px;
    }

    @media #{$square} {
        width: 180px;
    }

    @media #{$all-narrow} {
        position: absolute;
        left: 0;
        width: 250px;
        top: 0;
        min-height: 500px;
        transition: left 1s;
        box-shadow: 3px 3px 10px #777;
    }
}

#wp-site-menu.closed{
    @media #{$all-wide} {
        margin-left: -300px;
    }
    @media #{$square} {
        margin-left: -180px;
    }
    @media #{$all-narrow} {
        left: -250px;   
    }
}

//#wp-site-menu nav.menu ul {}

#wp-site-menu nav.menu li {
    list-style: none;
    position:relative;
    border-bottom: 1px solid #777;
}

#wp-site-menu nav.menu li a {
    display:block;
    text-decoration: none;
    font-size: 1em;
    color: #ddd;
    padding: 20px;
}

#wp-site-menu nav.menu li a.active {
    color: #F1F17D;
}

#wp-site-menu nav.menu li > ul {
    background-color: #777;
}

#wp-site-menu nav.menu li > ul li {
    display:none;
}

#wp-site-menu nav.menu li a.active + ul li {
    display: block;
}

#wp-site-menu nav.menu li > ul li {
    border: 0;
}

#wp-site-menu nav.menu li > ul > i {
    position: absolute;
    top: 27px;
    font-size: 14px;
    right: 25px;
    color: #999;
}

#wp-site-menu nav.menu li > ul a {
    margin: 0;
    padding: 15px;
    font-size: 0.9em;
    padding-left: 50px;
}

#wp-site-menu nav.menu li:hover a {
    background-color: #444;
}

#wp-site-menu nav.menu li:hover > ul a {
    background-color: #6a6a6a;
}

#wp-site-menu nav.menu li .image_container {
    width: 16px;
    position: absolute;
}

#wp-site-menu nav.menu li .image_container img {
    width:100%;
}

#wp-site-menu nav.menu li span {
    font-size: 1.2em;
}

#wp-site-menu nav.menu li .image_container + span {
    margin-left: 25px;
}

