#fixed-header {
  position:fixed;
  top:0;
  width:100%;
  height: 60px;
  background-color: #10396d;
  color: #fff;
  z-index: 80;
  box-shadow: 0 0 5px #000;
  display: none;
}

#fixed-header.hidden {
  display: none;
}

#fixed-header.visible {
  display: block;
}

#fixed-header .logo {
  height: 40px;
  margin: 10px;
}

#fixed-header .logo img {
  height: 100%;
}

#fixed-header .menu-opener {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

#fixed-header .menu-opener i {
  margin:auto;
  font-size: 30px;
  color: #fff;
}
