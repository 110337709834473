@import 'styles/variables/mqueries.scss';

#home-megamenu.initial {
  left:-60vw;
  @media #{$all-narrow} {
    left: -100vw;
  }
}

#home-megamenu.opened {
  left: 0 ;
}

#home-megamenu.closed {
  left:-60vw;
  @media #{$all-narrow} {
    left: -100vw;
  }
}

#home-megamenu {
  position: absolute;
  top: 150px;
  left: -60vw;
  width: 60vw;
  bottom:0;
  z-index: 32;
  overflow: hidden;
  transition: left 1s;
  @media #{$all-narrow} {
    top: 175px;
    left: -100vw;
    width: 100vw;
  }
}

#home-megamenu .fondo {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color: #10396d;
  z-index: 101;
}

#home-megamenu .img_fondo {
  position: absolute;
  z-index: 102;
  top: 30%;
  height: 30%;
  width: auto;
  left: 80px;
  opacity: 0.4;
}

#home-megamenu .img_fondo img {
  height: 100%;
}

#home-megamenu .submenu {
  position: absolute;
  top:50px;
  left:0;
  width:100%;
  height:80%;
  z-index: 102;
  overflow: auto;
}

#home-megamenu li {
  list-style: none;
  color: #fff;
}

#home-megamenu li.nivel-1 {
  width: 80%;
  margin: 2%;
  text-align: right;
  flex-grow: 1;
  padding-top: 2vh;
  @media #{$all-narrow} {
    width: 96%;
    text-align:center;
  }
}

#home-megamenu li.nivel-1 > a > span {
  font-weight: bold;
  color:#fff;
  font-size: 1.5em;
}

#home-megamenu li.nivel-1 > a:hover > span{
  color: #aaa;
}

#home-megamenu li.nivel-1 > .submenu {
  display: block;
  height: 80%;
  padding: 10% 5%;
  width:90%;
}

#home-megamenu li.nivel-2 {
  width: 100%;
  text-align:left;
  height:5vh;
}

#home-megamenu li.nivel-2 > a > span {
  color:#333;
}

#home-megamenu li.nivel-2 > a:hover > span{
  color: #777;
}
