@import 'styles/variables/colors.scss';
@import 'styles/variables/mqueries.scss';

#home-agenda {
  position: relative;
  width: 100%;
  padding: 40px 0;
  background-color: #6194af;
}

#home-agenda h1 {
  font-size:2.5em;
  color: #333;
  font-weight: normal;
  margin-bottom: 20px;
  padding-top: 5px;
  border-top:1px solid #333;
  text-align: left;
}

#home-agenda .list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
  padding-bottom: 60px;
}

#home-agenda .list article {
  position:relative;
  width: 450px;
  margin: 20px;
  overflow: hidden;
  @media #{$all-narrow-sq} {
    margin: 5%;
    width: 90%;
  }
}

#home-agenda .list article .fecha {
  width: 80px;
  float: left;
  color: #333;
  font-weight: bold;
  padding-top:3px;
}

#home-agenda .list article header {
  height: 30px;
}

#home-agenda .list article .area {
  width: 350px;
  float: right;
  text-align: right;
  box-sizing: border-box;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 0.9em;
}

#home-agenda .list article .area a {
  color: #193f69;
  text-decoration: none;
  font-weight: bold;
}

#home-agenda .list article .area a:hover {
    color: #333;
}

#home-agenda .list article .image_container {
  width: 160px;
  height: 84px;
}

#home-agenda .list article .image_container .image_wrapper {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#home-agenda .list article .image_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#home-agenda .list article .titulo {
  position: absolute;
  top: 25px;
  left: 180px;
  clear: both;
  margin-bottom: 10px;
}

#home-agenda .list article .titulo a {
  text-decoration: none;
  color: #fff;
  font-size: 0.75em;
  text-align: left;
  font-weight: bold;
}

#home-agenda .link-todas {
  position:absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

#home-agenda .link-todas a {
  color: #aaa;
  font-size: 1.2em;
  text-decoration: none;
}

#home-agenda .link-todas a:hover {
  color: #777;
}
