@import 'styles/variables/mqueries.scss';

#site-post article {
  margin: 30px auto;
  @media #{$super-wide} {
    max-width:1200px;
  }

  @media #{$wide} {
      max-width:1000px;
  }

  @media #{$square} {
      max-width:800px;
  }

  @media #{$all-narrow} {
      width:95%;
  }
}

#site-post .image_container {
  width:100%;
  height:auto;
  margin-bottom: 5px;

  @media #{$all-narrow} {
    width: 100%;
    float:none;
  }
}

#site-post .wp-block-file .wp-block-file__button {
  background: #32373c;
  border-radius: 2em;
  color: #fff;
  font-size: 13px;
  padding: .5em 1em;
  margin: 10px;
}

#site-post img {
  max-width: 100%;
  object-fit: contain;
}

#site-post .image_container img {
  width:100%;
  height:auto;
}

#site-post .post_content h1 {
  margin: 20px 0;
  font-size: 2.5em;
}

#site-post .post_content p {
  margin: 20px 0;
}

#site-post .post_content p img {
  @media #{$all-narrow} {
    width:100%;
  }
}

#site-post .content p img.alignleft,
#site-post .wp-caption.alignleft {
  float: left;
  margin: 10px;
  margin-left: 0;
}

#site-post .wp-caption .wp-caption-text{
  font-size: 0.8em;
  color: #999;
}

#site-post .content p img.alignright,
#site-post .wp-caption.alignright {
  float: right;
  margin: 10px;
  margin-right: 0;
}

#site-post ul {
    clear:both;
}

#site-post ul ul{
  margin-top: 15px;
}

#site-post ul li {
  list-style: circle;
  margin-left: 20px;
  margin-bottom: 15px;
  list-style-position: inside;
}

#site-post blockquote {
  width: 86%;
  padding: 10px 2%;
  border: 1;
  margin: 20px 5%;

  color: #999;
  font-size: 1.3em;
  font-style: italic;
}

#site-post .aligncenter {
  text-align: center;
}

#site-post #share-buttons {
  margin:20px 0;
  width:100%;
  display:flex;
  justify-content: space-between;
}

#site-post .wp-block-columns {
  display: flex;
}


//@import "styles/wp/site-post.scss";
