#fullscreen-image {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color: rgba(0,0,0,0.8);
  z-index: 999;
}

#fullscreen-image .close-trigger {
  position:absolute;
  top: 30px;
  right: 30px;
}

#fullscreen-image .close-trigger i {
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}

#fullscreen-image .img-container {
  position:absolute;
  top:80px;
  bottom:200px;
  left:80px;
  right:80px;
  text-align: center;
}

#fullscreen-image .img-container img {
  margin:auto;
  max-width:100%;
  max-height:100%;
}

#fullscreen-image .descripcion {
  position:absolute;
  width: 90%;
  left:0;
  bottom:0;
  text-align: center;
  color:#fff;
  font-size: 1.3em;
  padding: 20px 5%;
}
