@import 'styles/variables/mqueries.scss';

#main-menu {
  position: fixed;
  right: -40vw;
  width: 40vw;
  bottom:0;
  background-color: #122f4f;
  z-index: 50;
  color: #fff;
  @media #{$all-narrow} {
    top: 175px;
    width: 100vw;
    right: -100vw;
  }
  transition: 1s right;
}

#main-menu.arriba {
  top: 60px;
}

#main-menu.abajo {
  top: 150px;
}

#main-menu.closed {
  right: -40vw;
  @media #{$all-narrow} {
    right: -100vw;
  }
}

#main-menu.opened {
  right: 0;
}

#main-menu nav.menu > ul {
  position:absolute;
  bottom: 50px;
  right: 80px;
  @media #{$all-narrow} {
    display: flex;
    flex-wrap: wrap;
  }
}

#main-menu nav.menu li {
  margin:0;
  padding:15px;
  list-style: none;
  text-align: right;
  background: transparent;
  @media #{$all-narrow} {
    margin: 20px 1%;
    padding: 0;
    width: 48%;
    text-align:center;
  }
}


#main-menu nav.menu li .image_wrapper {
  display:none;
}

#main-menu nav.menu li.submenu-active {
  background-color: #10396d;
}

#main-menu nav.menu li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5em;
  font-weight:bold;
}

#main-menu nav.menu li a:hover {
  font-style: italic;
  color: #ccc;
}

#main-menu nav.menu li a.active {
  color: #00ffff;
}
