#site-posts .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 50px;
  }
  
  #site-posts .list article {
    width: 320px;
    margin: 30px;
    max-height: 550px;
    overflow: hidden;
  }
  
  #site-posts .list article .fecha {
    width: 100px;
    float: left;
  }
  
  #site-posts .list article header {
    height: 30px;
  }
  
  #site-posts .list article .area {
    width: 200px;
    float: right;
    text-align: right;
    box-sizing: border-box;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: #193f69;
  }
  
  #site-posts .list article .area a {
    color: #fff;
    text-decoration: none;
  }
  
  #site-posts .list article .area a:hover {
      color: #aaa;
  }
  
  #site-posts .list article .image_container {
    width: 100%;
    height: auto;
  }
  
  #site-posts .list article .image_container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  #site-posts .list article h3 {
    clear: both;
    margin-bottom: 10px;
  }
  
  #site-posts .list article h3 a {
    text-decoration: none;
    color: #6194af;
    font-size: 1.2em;
    text-align: left;
    font-weight: bold;
  }
  
  #site-posts .list article .date {
    float: left;
    margin-right: 8px;
    font-weight: bold;
  }