.megamenu .close-menu-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 30px;
  z-index: 110;
}

.megamenu .close-menu-btn > div {
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
}

.megamenu .close-menu-btn i {
  color: #fff;
  font-size: 24px;
}

.megamenu .close-menu-btn > div:hover i {
  color: #aaa;
}