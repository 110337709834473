@import 'styles/variables/mqueries.scss';

#home-inicio-menu {
  position: absolute;
  bottom: 10vh;
  right: 10%;
  width: 30%;
  height: auto;
  z-index: 30;
  color: #fff;
  font-size: 1.3em;
  background: rgba(0,0,0,0.4);
  @media #{$all-narrow} {
    width:auto;
    top: 60vh;
    bottom: 15px;
    left: 2vw;
    right: 2vw;
  }
}

#home-inicio-menu nav.menu > ul {
  @media #{$all-narrow} {
    display: flex;
    flex-wrap: wrap;
  }
}

#home-inicio-menu nav.menu li {
  margin:0;
  padding:14px;
  list-style: none;
  text-align: right;
  background: transparent;
  transition: 0.5s all;

  @media #{$all-wide-sq} {
    padding: 1.2vh;
  }
  @media #{$all-narrow} {
    margin: 15px 1%;
    padding: 0;
    width: 48%;
    text-align:center;
    box-sizing: border-box;
  }
}

#home-inicio-menu nav.menu li .image_wrapper {
  display:none;
}

#home-inicio-menu nav.menu li.submenu-active {
  background-color: #10396d;
}

#home-inicio-menu nav.menu li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5em;
  font-weight:bold;

  @media #{$wide}, #{$square} {
    font-size: 2.5vh;
  }

  @media #{$all-narrow} {
    font-size: 1.2em;
  }
}

#home-inicio-menu nav.menu li a:hover {
  font-style: italic;
  color: #ccc;
}

#home-inicio-menu nav.menu li a.active {
  color: #00ffff;
}
