@import "styles/variables/colors.scss";
@import "styles/variables/mqueries.scss";
.card {
  margin: 20px auto;
  box-sizing: border-box;
  width: 350px;
  min-height: 180px;
  padding: 15px;
  background-color: #10396d;
  border-radius: 10px;
  position: relative;

  @media #{$all-narrow} {
    width: 80%;
  }
  
  & h3{
      text-align: left;
      color: #fff !important;
      margin: 0 !important;
      font-size: 1.2em !important;
  }
  & .descripcion {
      margin-top:20px;
      margin-bottom: 20px;
      color: #ddd;
  }
  & .actions {
      display:flex;
      position:absolute;
      bottom: 15px;
      left:15px;
  }
  & .btn {
      background-color: $background-1;
      border-radius: 8px;
      width: 40px;
      padding:8px;
      margin:2px;
      text-align: center;
      & a {
        color: #fff;
      }
  }
  & .btn:hover {
    background-color: #0079a6;
  }
  & .icons {
    position: absolute;
    display: flex;
    bottom: 10px;
    right: 12px;
      &.popover__message {
        text-align: center;
       font-weight: 0;
      }
      
      & i{
        color:#ccc;
        cursor: help;
        //background-color: $background-1;
        justify-content: center;
        text-align: center;
        display: inline-flex;
        flex-direction: column;
        font-size: 1em;
        padding: 4px;
        border: 2px solid $background-1;
        margin: 2px;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        & .popover__content {
          opacity: 0;
          visibility: hidden;
          position: absolute;
          bottom: -30px;
          left: 0px;
          color: #333;
          transform: translate(-300px, 0px);
          background-color: #bfbfbf;
          padding: 3px;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
          width: auto;

          & p {
            width: 300px;
          }
        }

        &:hover{
          & .popover__content {
            z-index: 10;
            opacity: 1;
            visibility: visible;
            transform: translate(-200px, 0);
            transition: all 0.5s;
            @media #{$all-narrow} {
                  z-index: 10;
                  opacity: 1;
                  visibility: visible;
                  transform: translate(-5%, -0px);
                  transition: all 0.5s;
            }
            @media #{$square} {
              z-index: 10;
              opacity: 1;
              visibility: visible;
              transform: translate(-5%, -0px);
              transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
            }
          }
        }

       

   
       
        &:not(:hover){
          animation-name: card-animation-hidden;
          //tiempo de duracion
          animation-duration: .5s;
          // velocidad lineal, reproduce a la misma velocidad
          animation-timing-function: linear;
          // quedarse en el ultimo estilo del fotograma
          animation-fill-mode: forwards;
        }

      }
  }

}

@keyframes card-animation-hidden {
  0% { //al inicio
    opacity: 0;
  }
  50% { // mitad de tramo
    opacity: 0.7;
  }
  100% { //fin de tramo
    opacity: 1;
    transform: rotate(-360deg);
  }
} 
