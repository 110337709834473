#carreras-departamento {
    max-width: 1200px;
    margin: auto;
}

#carreras-departamento ul {
    display:flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

#carreras-departamento ul li {
    width: 300px;
    height: auto;
    background-color: #10396d;
    list-style: none;
    margin: 5px;
    padding: 20px;
    color: #fff;
    border-radius: 8px;
}

#carreras-departamento ul li a {
    color: #fff;
}

#carreras-departamento ul li a:hover {
    color: #9ee5ff;
}