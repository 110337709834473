@import 'styles/variables/colors.scss';
@import 'styles/variables/mqueries.scss';

#home-novedades {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding-top: 40px;
}

#home-novedades h1 {
  font-size: 2.5em;
  color: #333;
  font-weight: normal;
  margin-bottom: 20px;
  padding-top: 5px;
  border-top: 1px solid #333;
  text-align: left;  
}

#home-novedades .list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
  padding-bottom: 60px;
}

#home-novedades .list article {
  width: 280px;
  margin: 10px;
  overflow: hidden;
  @media #{$all-narrow} {
    margin: 5%;
    width: 90%;
  }
}

#home-novedades .list article .fecha {
  width: 80px;
  float: left;
}

#home-novedades .list article header {
  height: 30px;
}

#home-novedades .list article .area {
  width: 180px;
  float: right;
  text-align: right;
  box-sizing: border-box;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #193f69;
  font-size: 0.9em;
}

#home-novedades .list article .area a {
  color: #fff;
  text-decoration: none;
}

#home-novedades .list article .area a:hover {
    color: #aaa;
}

#home-novedades .list article .image_container {
  margin: 10px auto;
}

#home-novedades .list article .image_container .image_wrapper {
  width: 100%;
  height: auto;
}

#home-novedades .list article .image_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#home-novedades .list article .titulo {
  clear: both;
  margin-bottom: 10px;
}

#home-novedades .list article .titulo a {
  text-decoration: none;
  color: #6194af;
  font-size: 1.1em;
  text-align: left;
  font-weight: bold;
}

#home-novedades .link-todas {
  width: 100%;
  text-align: center;
  height: 5em;
}

#home-novedades .link-todas a {
  color: #fff;
  background: #263238;
  padding: 1.5em;
  font-size: 1.2em;
  text-decoration: none;
}

#home-novedades .link-todas a:hover {
  background: #37474F;
}
