@import 'styles/variables/mqueries.scss';

#plano {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 40px 0;
}

#plano h1 {
  font-size:2.5em;
  color: #333;
  font-weight: normal;
  margin-bottom: 20px;
  padding-top: 5px;
  border-top:1px solid #333;
}

#plano nav.menu ul {
  display:flex;
  flex-wrap: wrap;
  justify-content:space-between;

  @media #{$all-narrow} {
    display: block;
  }
}

#plano nav.menu ul li {
  width: 220px;
  height: 100px;
  margin: 10px;
  list-style: none;
  position:relative;

  @media #{$all-narrow} {
    max-width: 90%;
    margin: 30px auto;
    height:auto;
  }
}

#plano nav.menu ul li .image_wrapper {
  position:absolute;
  width:100%;
  height:100%;
  text-align: center;
  line-height: 100%;
  display:flex;
  align-content: center;

  @media #{$all-narrow} {
    position:relative;
  }
}

#plano nav.menu ul li .image_wrapper img {
  max-width: 90%;
  max-height: 90%;
  margin: auto;
  vertical-align: middle;

  @media #{$all-narrow} {
    width: 90%;
    height:auto;
    max-height: none;
  }
}

#plano nav.menu ul li span {
  display:none;
}

.flex-container{
  display: flex;
  @media only screen and (max-width: 640px) {
    flex-direction: column;
  }
}

#plano-box {
 overflow-x: scroll;
 overflow-y: scroll;
}

.plano-aside {
  margin-left: 1em;
  position: -webkit-sticky;
  position: sticky;
  align-self: flex-start;
  top: 70px;
}

.search-box {
  padding: 1em 0.4em 1em 0.4em;
  background-color: white;
}

#search-input {
  background-color: #eee;
  border: 1px solid #008dc2;
  border-radius: 10px;
  justify-content: center;
  padding: 0.3em;
  color: #555;
}

.btn-group
{
  margin: 3em;
  text-align: center;
  background-color: #eee;
  float: right;
  position: absolute;
  right: 250px;
}

.btn-title {
  padding-bottom: 0.3em;
  background-color: #eee;
}

#reset-btn{
  margin-left: 0.5em;
}

.places-list {
  overflow-y: scroll;
  height:500px;
}

.places-list > ul {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.places-list-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
}

.places-list-title {
  padding: 1em;
  background-color: #10396d;
  color: white;
}

.place-type-title {
  margin-left: 0.8em;
}

.place-type-li {
  margin-left: 1.5em;
}

.place-type-li:hover {
  cursor: pointer;
  color: white;
  background: #F57C00;
}

.places-group {
  margin-top: 1em;
}

//Plano
//
path.place:hover {
  cursor: help;
}

path.aula{
  fill: #ed3237;
}

path.aula:hover {
  fill:#ff7f82ff;
}

path.admin {
  fill: #d2d3d5;
}

path.admin:hover {
  fill:#E1E1E3;
}

path.aut {
   fill: #0071a1;
}

path.aut:hover {
  fill:#008DC9;
}

path.sala {
   fill: #00a859;
}
 
path.sala:hover {
  fill:#00D170;
}

path.dpto {
   fill: #ffcc29;
}

path.dpto:hover {
  fill:#FFE37B;
}

path.aux {
   fill: #ffa216;
}

path.aux:hover {
  fill:#FFBC52;
}

