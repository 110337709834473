#transition {
    background-color: #fff;
    position:absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    z-index: 50;
}

#transition + #transition {
    z-index: 52;
    position: absolute;
}

#fondo-transition {
    background-color: #10396d;
    position:absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 0;
    z-index: 50;
    transition: 1s height;
}

.trans-exited #fondo-transition {
    height: 0;
    position:fixed;
}

.trans-exit #fondo-transition{
    height:100%;
    position:fixed;
}
