@import 'styles/variables/mqueries.scss';

section#home-inicio .slider {
  position: absolute;
  top: 150px;
  left: 0;
  width: 100%;
  bottom: 0;
  z-index:20;

  @media #{$all-narrow} {
    top: 165px;
  }
}

section#home-inicio .slider .list {
  display:none;
}

section#home-inicio .slider .page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media #{$all-narrow} {
    width:100%;
  }
}

section#home-inicio .slider .page article .image_container .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index:25;
  background-position: 75% center;
  background-size: cover;
  opacity: 0.5;
}

section#home-inicio .slider .page article .post_content {
  position:absolute;
  top:20vh;
  left:0;
  width:40%;
  height:auto;
  z-index:30;

  margin:0;
  padding: 10px 3%;
  padding-top: 20px;
  text-align:center;
  box-sizing: border-box;

  @media #{$all-narrow} {
    top: 10px;
    width:100%;
    max-height: 40vh;
    overflow: hidden;
  }
}

section#home-inicio .slider .page article.mode1 .post_content {
  background-color: rgba(0,0,0,0.5);
}

section#home-inicio .slider .page article.mode2 .post_content{
  padding: 0;

  top: 8%;
  left: 8%;
  width: 50%;
  height: 90%;

  @media #{$all-narrow} {
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
  }
}

section#home-inicio .slider .page article.mode2 .post_content h2 {
  display:none;
}

section#home-inicio .slider .page article .post_content h2 a {
  color: #fff;
  font-size: 1.2em;
}

section#home-inicio .slider .page article .post_content .date {
  display: none;
}

section#home-inicio .slider .page article.mode1 .post_content .content {
  display:none;
}

section#home-inicio .slider .page article.mode2 .post_content .content img{
  display: block;
  position: absolute;
  width: 100%;
  height: 80%;
  object-fit: contain;
  filter: drop-shadow(3px 3px 20px #091f3c);
}


section#home-inicio .slider .page article .post_content .excerpt {
  margin:20px;
  color: #ccc;
  font-size: 1.1em;
  font-style: italic
}

section#home-inicio .slider .page article.mode2 .post_content .excerpt {
  display:none;
}

section#home-inicio .slider .ver-todas {
  display:none;
}


section#home-inicio .slider .page article .post_content .content .wp-block-button a {
  display: inline-block;
  background-color: #008dc2;
  padding: 10px;
  margin:10px;
  border-radius: 8px;
  color: #fff;
}
