@import 'styles/variables/mqueries.scss';
@import 'styles/variables/colors.scss';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');
@import 'gutemberg.scss';

* {
  margin:0;
  padding:0;
}

body {
  font-family: 'Open Sans', sans-serif;
  color: $text;
  overflow-x: hidden;
  line-height: 1.5;

  @media #{$super-wide} {
      font-size:14px;
  }

  @media #{$wide} {
      font-size:12px;
  }

  @media #{$square} {
      font-size:10px;
  }

  @media #{$all-narrow} {
      font-size:12px;
  }

}

h1,h2,h3 {
    line-height: 1.2;
}

.wrapper-central {
  position:relative;
  margin: auto;
  height:100%;

  @media #{$super-wide} {
      width:1200px;
  }

  @media #{$wide} {
      width:1000px;
  }

  @media #{$square} {
      width:800px;
  }

  @media #{$all-narrow} {
      width:95%;
  }
}

a {
    text-decoration: none;
    color: #008dc2;
}

a:hover {
    color: #10396d;
}

table.inPost_table{
	width:90%;
	margin:auto;
	margin-top:10px;
    margin-bottom:10px;
    border-collapse: collapse;
}

table.inPost_table td{
	border:1px solid #999;
    padding:10px; 
}

table.inPost_table th {
    color: #008dc2;
    font-size: 1.2em;
}

#site-post img {
  height: auto;
}

.btn-box {
  margin: 3em 1em 3em 1em;
}

.btn-box-center {
  margin: 3em 1em 3em 1em;
  text-align: center;
}
