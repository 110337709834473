@import 'styles/variables/mqueries.scss';

#home-accesos {
  position: relative;
  width: 100%;
  background-color: #fff;
}

#home-accesos h1 {
  font-size:2.5em;
  color: #333;
  font-weight: normal;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-top: 5px;
  border-top:1px solid #333;
}

#home-accesos nav.menu ul {
  display:flex;
  flex-wrap: wrap;
  justify-content:space-between;

  @media #{$all-narrow} {
    display: block;
  }
}

#home-accesos nav.menu ul li {
  width: 21%;
  height: 100px;
  margin: 2%;
  list-style: none;
  position:relative;

  @media #{$all-narrow} {
    max-width: 90%;
    margin: 30px auto;
    height:auto;
  }
}

#home-accesos nav.menu ul li .image_wrapper {
  position:absolute;
  width:100%;
  height:100%;
  text-align: center;
  line-height: 100%;
  display:flex;
  align-content: center;

  @media #{$all-narrow} {
    position:relative;
  }
}

#home-accesos nav.menu ul li .image_wrapper img {
  max-width: 90%;
  max-height: 90%;
  margin: auto;
  vertical-align: middle;

  @media #{$all-narrow} {
    width: 90%;
    height:auto;
    max-height: none;
  }
}

#home-accesos nav.menu ul li span {
  display:none;
}

