@import 'styles/variables/mqueries.scss';

#home-plano {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 40px 0;
}

#home-plano h1 {
  font-size:2.5em;
  color: #333;
  font-weight: normal;
  margin-bottom: 20px;
  padding-top: 5px;
  border-top:1px solid #333;
}

#home-plano nav.menu ul {
  display:flex;
  flex-wrap: wrap;
  justify-content:space-between;

  @media #{$all-narrow} {
    display: block;
  }
}

#home-plano nav.menu ul li {
  width: 220px;
  height: 100px;
  margin: 10px;
  list-style: none;
  position:relative;

  @media #{$all-narrow} {
    max-width: 90%;
    margin: 30px auto;
    height:auto;
  }
}

#home-plano nav.menu ul li .image_wrapper {
  position:absolute;
  width:100%;
  height:100%;
  text-align: center;
  line-height: 100%;
  display:flex;
  align-content: center;

  @media #{$all-narrow} {
    position:relative;
  }
}

#home-plano nav.menu ul li .image_wrapper img {
  max-width: 90%;
  max-height: 90%;
  margin: auto;
  vertical-align: middle;

  @media #{$all-narrow} {
    width: 90%;
    height:auto;
    max-height: none;
  }
}

#home-plano nav.menu ul li span {
  display:none;
}

.image-container {
  position: relative;
  display: inline-block;
}

.text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(0, 0, 0, 0.5); /* Background color of the text overlay */
  color: white; /* Text color */
  text-align: center;
  font-size: 2em;
  font-weight: bold;
}

.image-container:hover .text-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

#plano-curza {
  width: 100%;
}
