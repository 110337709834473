@import "styles/variables/colors.scss";
@import "styles/variables/mqueries.scss";
#oferta-academica{
    margin: 20px 0;
    & .flex-ofertas{
        display: flex;
        flex-wrap: wrap;
        flex: 1 20%;
        margin: auto;
        align-content: center;
        @media #{$all-narrow} {
            display: inherit;
        }
        
    }
    & .no-result{
        color: $font-1;
        & span {
            color: #333;
        }
    }
    
    & h2 {
        font-size: 1.8em;
        color:
        #333;
        border-bottom: 2px solid
        #333;
        width: 100%;
        padding-bottom: 8px;
        margin: 20px 0;
        clear: both;
    }
}
#mostrar-oferta{
    & header {
        width: 100%;
        position: relative;
        height: 180px;
        background-color: #008dc2;
        z-index: 30;
        
        & .fondo {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("/images/fondo_header_site.png"), url("/images/fondos/fondo4_fino.jpg");
            background-size: cover;
            background-position: center 25%;
            z-index: 31;
        }

        & h1 {
            position: absolute;
            top: 70px;
            left: 120px;
            color:
            #fff;
            font-weight: 300;
            text-transform: uppercase;
            font-size: 40px;
            line-height: 40px;
            text-shadow: 0 0 20px
            #008dc2,0 0 20px #008dc2;
            z-index: 32;

            @media #{$all-narrow} {
                width: 80%;
                margin: 10%;
                font-size: 30px;
                text-align: center;
                top: 0;
                left: 0;
            }
        }
    }
}