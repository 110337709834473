@import 'styles/variables/mqueries.scss';

#main-megamenu.initial {
  left:-60vw;
  @media #{$all-narrow} {
    left: -100vw;
  }
}

#main-megamenu.opened {
  left: 0 ;
}

#main-megamenu.closed {
  left:-60vw;
  @media #{$all-narrow} {
    left: -100vw;
  }
}

#main-megamenu {
  position: fixed;
  left: -60vw;
  width: 60vw;
  bottom:0;
  z-index: 100;
  overflow: hidden;
  transition: left 1s;
  @media #{$all-narrow} {
    top: 175px;
    left: -100vw;
    width: 100vw;
  }
}


#main-megamenu.arriba {
  top: 60px;
}

#main-megamenu.abajo {
  top: 150px;
}


#main-megamenu .fondo {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color: #10396d;
  z-index: 101;
}

#main-megamenu .submenu {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index: 102;

}

#main-megamenu li {
  list-style: none;
  color: #fff;
}

#main-megamenu li.nivel-1 {
  width: 80%;
  margin: 2%;
  text-align: right;
  flex-grow: 1;
  padding-top: 2vh;
  @media #{$all-narrow} {
    width: 96%;
    text-align:center;
  }
}

#main-megamenu li.nivel-1 > a > span {
  font-weight: bold;
  color:#fff;
  font-size: 1.5em;
}

#main-megamenu li.nivel-1 > a:hover > span{
  color: #aaa;
}

#main-megamenu li.nivel-1 > .submenu {
  display: block;
  height: 80%;
  padding: 10% 5%;
  width:90%;
}

#main-megamenu li.nivel-2 {
  width: 100%;
  text-align:left;
  height:5vh;
}

#main-megamenu li.nivel-2 > a > span {
  color:#333;
}

#main-megamenu li.nivel-2 > a:hover > span{
  color: #777;
}
