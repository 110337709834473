@import 'styles/variables/colors.scss';
@import 'styles/variables/mqueries.scss';

#tramites {
    min-height: 500px;
}

section#tramites .box-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

section#tramites .box {
    list-style: none;
    text-align: center;
    padding: 1em;
    border: solid 3px;
    width: 300px;
    margin: 20px;
}

section#tramites li img {
    width: 100%;
}

.description{
    margin: 1.5em;
}

.btn{
    background-color: #006064;
    border: 0px;
    color: white;
    padding: 1.3em;
}

.btn-sm{
  padding: 1em;
}

.btn:hover{
    background-color: #0097A7;
    cursor: pointer;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; 
  z-index: 50; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4); 
}

/* Modal Content */
.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: 10% auto;
    padding: 0;
    border: 1px solid #888;
    width: 70%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #10396d;
  color: white;
}

.modal-body {
  padding: 1.5em;
  font-size: 1.3em;
}

.modal-body ul {
  margin: 2em;
}

.modal-body p {
  margin-top: 1em;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}
