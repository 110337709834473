@import "styles/variables/colors.scss";
@import 'styles/variables/mqueries.scss';

#home-departamentos {
  position: relative;
  width: 100%;
  padding: 40px 0;
  background-color: #999;
}
#home-departamentos h1 {
  font-size:2.5em;
  color: #fff;
  font-weight: normal;
  margin-bottom: 20px;
  padding-top: 5px;
  border-top:1px solid #fff;
  text-align: left;
}
#home-departamentos .fondo {
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/images/fondos/fondo4.jpg');
  background-position: center;
  background-size: cover;
  z-index: 40;
  opacity: 0.2;
}

#home-departamentos .wrapper-central {
  z-index:41;
}

#home-departamentos ul {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  align-items: stretch;
  @media #{$all-narrow} {
    display:block;
    width:100%;
  }
}
#home-departamentos ul li {
  flex: 20%;
  margin: 1px;
  height: 80px;
  list-style: none;
  box-sizing: border-box;
  transition: transform .5s;
  z-index: 30;
  &.azul-claro{
    background-color: $background-1;
  }
  &.azul-medio{
    background-color: $background-2;
  }
  &.azul-oscuro{
    background-color: $background-3;
  }

  &:hover {
    z-index: 31;
    transform: scale(1.1);
  }
}
#home-departamentos ul li a {
  text-decoration: none;
  display:flex;
  height:100%;
}
#home-departamentos ul li span {
  font-size: 1.2em;
  color: white;
  margin:auto;
  display: table-cell; 
  vertical-align: middle; 
  text-align: center; 
}