section#share-buttons {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    margin-bottom: 20px;
}

section#share-buttons > div.SocialMediaShareButton {
}

section#share-buttons > div.SocialMediaShareButton > div {
    cursor: pointer;
}
