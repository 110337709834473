@import 'styles/variables/mqueries.scss';

#site-archive {
  min-height: 78vh;
  position: relative;
  padding-bottom: 20px;
}

#site-archive h1 {
  font-size: 1.8em;
  color: #333;
  border-bottom: 2px solid #333;
  width: 100%;
  padding-bottom: 8px;
  margin: 20px 0;
  clear: both;
}

#site-archive .list-pagination {
  float: right;
}

#site-archive .list-pagination ul {
  display: flex;
}

#site-archive .list-pagination ul li {
  list-style: none;
  margin: 0 5px;
}

#site-archive .list-pagination ul li a {
  cursor: pointer;
}

#site-archive .list-pagination ul li.disabled a {
  color: #777;
  cursor: not-allowed;
}

#site-archive .list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  left:5%;
  width:90%;
  margin:auto;
}

#site-archive .list article {
  width: 320px;
  margin: 30px;
  max-height: 550px;
  overflow: hidden;
}

#site-archive .list article h2 {
  font-size: 1.3em;
}

#site-archive .list article .image_container{
  height: 160px;
  margin-bottom:10px;
}

#site-archive .list article .image_container .image {
  height: 160px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#site-archive .list article .date {
  margin-bottom: 10px;
  text-align: right;
}
