@import 'styles/variables/mqueries.scss';

#header {
    position: relative;
    width:100%;
    top:0;
    height:150px;

    z-index: 38;

    @media #{$all-narrow} {
        height: 220px;
    }
    
    & > .fondo {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-color: #10396d;
        opacity: 1;
        z-index: 38;
    }

    & .wrapper-central {
        z-index:40;
        @media #{$all-narrow} {
            text-align: center;
        }
    }

    & .logo {
        position: absolute;
        top: 40px;
        height: 80px;
        width:450px;

        @media #{$all-narrow} {
            position:relative;
            top:20px;
            width:342px;
            margin:auto;     
        }
    }

    & .logo img {
        height: 100%;
        width:auto;
        float:left;
        margin-right: 10px;
    }

    & .logo .logo-text {
        color: #fff;
    }

    & .logo .logo-text .titulo{
        font-size: 1.5em;
        font-weight: bold;
        color: #fff;
    }
    & .logo .logo-text .descripcion{
        color: #fff;
    }
    & .logo .logo-text .unco{
        color: #fff;
    }

    &  .wrapper-servicios {
        float:right;
        
        @media #{$all-narrow} {
            width: 350px;
            margin:auto;
            float:none;
        }
    }

    &  .redes-sociales {
        float: right;
        margin-top:60px;
        @media #{$all-narrow} {
            position:relative;
            margin-top:40px;
            float:left;
            max-width: 156px;
        }
    }

    & .redes-sociales ul {
        display: flex;
        flex-wrap: wrap;
    }
    
    & .redes-sociales li {
        list-style: none;
        width: 32px;
        height: 32px;
        margin: 0 10px;
        border: 1px solid #fff;
        border-radius: 50%;
        position: relative;
        text-align: center;
        color:white;
        box-sizing: border-box;
        padding-top: 10px;
        font-size: 10px;
        margin-bottom: 10px;
    }

    & .redes-sociales li a {
        display:block;
        position:absolute;
        top:0;
        height: 0;
        width:100%;
        height:100%;
    }
    & .redes-sociales li span {
        display: none;
    }

    & .telefonos {
        margin-top:70px;
        float: right;
        color: #fff;
        margin-right: 20px;
        @media #{$all-narrow} {
            position:relative;
            float:right;
            margin-right: 0px;
            margin-top:50px;
        }
    }

    & .telefonos i {
        margin-right: 5px;
    }
    & .menu-opener {
        float: right;
        margin-top: 62px;
        margin-left: 20px;
        cursor: pointer;
        @media #{$all-narrow} {
            float:none;
            position:absolute;
            margin:0;
            top:175px;
            width:100%;
        }
    }
    & .menu-opener span {
        display:none;
        @media #{$all-narrow} {
            display: inline;
            margin-top: -10px;
            margin-right: 10px;
            font-size: 1.2em;
            color: #fff;
        }
    }
    & .menu-opener i {
        margin:auto;
        font-size: 30px;
        color: #fff;
        @media #{$all-narrow} {
            font-size: 1.2em;
        }
    }
}
