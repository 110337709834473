#home-separador {
  position:relative;
  height: 200px;
  width: 100%;
  padding: 40px 0;
}

#home-separador .fondo {
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/images/fondos/fondo5.jpg');
  background-attachment: fixed;
  background-position: top;
  background-size:cover;
  z-index: 40;
  opacity: 1;
}
