@import 'styles/variables/colors.scss';
@import 'styles/variables/mqueries.scss';

#novedades {
  position: relative;
  width: 100%;
  background-color: #fff;
  line-height: 1.4;
  & > header {
    width: 100%;
    position: relative;
    height: 180px;
    background-color: #008dc2;
    z-index: 30;
    
    & .fondo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("/images/fondo_header_site.png"), url("/images/fondos/fondo4_fino.jpg");
        background-size: cover;
        background-position: center 25%;
        z-index: 31;
    }

    & h1 {
        position: absolute;
        top: 70px;
        left: 120px;
        color:
        #fff;
        font-weight: 300;
        text-transform: uppercase;
        font-size: 40px;
        line-height: 40px;
        text-shadow: 0 0 20px
        #008dc2,0 0 20px #008dc2;
        z-index: 32;

        @media #{$all-narrow} {
            width: 80%;
            margin: 10%;
            font-size: 30px;
            text-align: center;
            top: 0;
            left: 0;
        }
    }
  }
}

#novedades .list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 50px;
}

#novedades .list article {
  width: 320px;
  margin: 30px;
  max-height: 550px;
  overflow: hidden;
}

#novedades .list article .fecha {
  width: 100px;
  float: left;
}

#novedades .list article header {
  height: 30px;
}

#novedades .list article .area {
  width: 200px;
  float: right;
  text-align: right;
  box-sizing: border-box;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #193f69;
}

#novedades .list article .area a {
  color: #fff;
  text-decoration: none;
}

#novedades .list article .area a:hover {
    color: #aaa;
}

#novedades .list article .image_container {
  width: 100%;
  height: auto;
}

#novedades .list article .image_container img {
  width: 100%;
  height: 164px;
  object-fit: cover;
}

#novedades .list article .titulo {
  clear: both;
  margin-bottom: 10px;
}

#novedades .list article .titulo a {
  text-decoration: none;
  color: #6194af;
  font-size: 1.2em;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
}

#novedades .link-todas {
  position:absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
}

#novedades .link-todas a {
  color: #aaa;
  font-size: 1.2em;
  text-decoration: none;
}

#novedades .link-todas a:hover {
  color: #777;
}
