@import "styles/variables/colors.scss";
@import "styles/variables/mqueries.scss";

$actual-color: $background-2;

.tabs {
    max-width:800px;
    margin: 30px auto;

    & .containerBtn ul {
        //height: 80px;
        display:flex;
        justify-content: center;
    }
    & .containerBtn li{
        list-style: none;
        cursor: pointer;
        border: none;
        outline: none;
        padding: 10px;
        margin: 0 10px 0 0;
        color: white;
        &:nth-child(even){
            background-color: $background-2;
        }
        &:nth-child(odd){
            background-color: $background-1;
        }
        &.active {
            background-color: $background-3;

        }
        &:not(.active){
            margin-bottom:3px;
            //flex: content;
            

        }
        & .title {
            font-size: medium;
            display: inline-block;
            font-weight: bold;
            text-align: center;
        }
        & .subtitle {
            font-size: 1.2em;
            font-weight: none;
            text-align: center;
        }
        & span{
            display:flex;
            margin:5px 0 0 0;
            justify-content: center;
            &.fa-chevron-down{
                font-size:20px;
                margin:5px;
               -webkit-transition: all 0.5s;
               transition: all 0.5s;
               &.up{
                font-size: 15px;
                transform: rotate(180deg);  
               }
           }
           
        }
        
    }
    & .tabContent{
        max-width: 790px;
        width:100%;
        background-color: $background-3;
        padding: 10px 0px;
        color: white;
        text-align: center;
        box-sizing: border-box;
        font-size: 22px;
        &.hide {
            background-color: transparent;
        }
    }

    
}