@import "styles/variables/colors.scss";
@import 'styles/variables/mqueries.scss';

#wp-site-header-fixed {
    width:100%;
    position: fixed;
    top: 60px;
    height: 60px;
    z-index: 45;
    background-color: #008dc2;
}


#wp-site-header-fixed .fondo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("/images/fondo_header_site.png"), url("/images/fondos/fondo4_fino.jpg");
    background-size: cover;
    background-position: center 45%;
}

#wp-site-header-fixed h1 {
   position: absolute;
   top: 10px;
   left: 120px;
   color: #fff;
   font-weight: 300;
   text-transform: uppercase;
   font-size: 30px;
   line-height: 40px;
   text-shadow: 0 0 20px #008dc2,0 0 20px #008dc2 ;

   @media #{$all-narrow} {
        width: 80%;
        margin: 10px 10%;
        font-size: 15px;
        text-align: center;
        top: 0;
        left: 0;
        font-weight: bold;
    }
}

#wp-site-header-fixed .button-site {
    position:absolute;
    bottom: 18px;
}

#wp-site-header-fixed .button-site i {
    font-size: 20px;
    line-height: 20px;
    color: #fff;
    cursor: pointer;
}

#wp-site-header-fixed .button-site span {
    display: none;
}

#wp-site-header-fixed .button-menu {
    left: 20px;
}

#wp-site-header-fixed .button-sidebar {
    right: 20px;
}
