@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
#fullscreen-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999; }

#fullscreen-image .close-trigger {
  position: absolute;
  top: 30px;
  right: 30px; }

#fullscreen-image .close-trigger i {
  font-size: 30px;
  color: #fff;
  cursor: pointer; }

#fullscreen-image .img-container {
  position: absolute;
  top: 80px;
  bottom: 200px;
  left: 80px;
  right: 80px;
  text-align: center; }

#fullscreen-image .img-container img {
  margin: auto;
  max-width: 100%;
  max-height: 100%; }

#fullscreen-image .descripcion {
  position: absolute;
  width: 90%;
  left: 0;
  bottom: 0;
  text-align: center;
  color: #fff;
  font-size: 1.3em;
  padding: 20px 5%; }

#main-menu .close-menu-btn {
  position: absolute;
  left: 20px;
  top: 20px;
  height: 30px;
  z-index: 110; }

#main-menu .close-menu-btn > div {
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer; }

#main-menu .close-menu-btn i {
  color: #fff;
  font-size: 24px; }

#main-menu .close-menu-btn > div:hover i {
  color: #aaa; }

#main-menu {
  position: fixed;
  right: -40vw;
  width: 40vw;
  bottom: 0;
  background-color: #122f4f;
  z-index: 50;
  color: #fff;
  -webkit-transition: 1s right;
  transition: 1s right; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #main-menu {
      top: 175px;
      width: 100vw;
      right: -100vw; } }

#main-menu.arriba {
  top: 60px; }

#main-menu.abajo {
  top: 150px; }

#main-menu.closed {
  right: -40vw; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #main-menu.closed {
      right: -100vw; } }

#main-menu.opened {
  right: 0; }

#main-menu nav.menu > ul {
  position: absolute;
  bottom: 50px;
  right: 80px; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #main-menu nav.menu > ul {
      display: flex;
      flex-wrap: wrap; } }

#main-menu nav.menu li {
  margin: 0;
  padding: 15px;
  list-style: none;
  text-align: right;
  background: transparent; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #main-menu nav.menu li {
      margin: 20px 1%;
      padding: 0;
      width: 48%;
      text-align: center; } }

#main-menu nav.menu li .image_wrapper {
  display: none; }

#main-menu nav.menu li.submenu-active {
  background-color: #10396d; }

#main-menu nav.menu li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5em;
  font-weight: bold; }

#main-menu nav.menu li a:hover {
  font-style: italic;
  color: #ccc; }

#main-menu nav.menu li a.active {
  color: #00ffff; }

#fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: #10396d;
  color: #fff;
  z-index: 80;
  box-shadow: 0 0 5px #000;
  display: none; }

#fixed-header.hidden {
  display: none; }

#fixed-header.visible {
  display: block; }

#fixed-header .logo {
  height: 40px;
  margin: 10px; }

#fixed-header .logo img {
  height: 100%; }

#fixed-header .menu-opener {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer; }

#fixed-header .menu-opener i {
  margin: auto;
  font-size: 30px;
  color: #fff; }

#header {
  position: relative;
  width: 100%;
  top: 0;
  height: 150px;
  z-index: 38; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #header {
      height: 220px; } }
  #header > .fondo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #10396d;
    opacity: 1;
    z-index: 38; }
  #header .wrapper-central {
    z-index: 40; }
    @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
      #header .wrapper-central {
        text-align: center; } }
  #header .logo {
    position: absolute;
    top: 40px;
    height: 80px;
    width: 450px; }
    @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
      #header .logo {
        position: relative;
        top: 20px;
        width: 342px;
        margin: auto; } }
  #header .logo img {
    height: 100%;
    width: auto;
    float: left;
    margin-right: 10px; }
  #header .logo .logo-text {
    color: #fff; }
  #header .logo .logo-text .titulo {
    font-size: 1.5em;
    font-weight: bold;
    color: #fff; }
  #header .logo .logo-text .descripcion {
    color: #fff; }
  #header .logo .logo-text .unco {
    color: #fff; }
  #header .wrapper-servicios {
    float: right; }
    @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
      #header .wrapper-servicios {
        width: 350px;
        margin: auto;
        float: none; } }
  #header .redes-sociales {
    float: right;
    margin-top: 60px; }
    @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
      #header .redes-sociales {
        position: relative;
        margin-top: 40px;
        float: left;
        max-width: 156px; } }
  #header .redes-sociales ul {
    display: flex;
    flex-wrap: wrap; }
  #header .redes-sociales li {
    list-style: none;
    width: 32px;
    height: 32px;
    margin: 0 10px;
    border: 1px solid #fff;
    border-radius: 50%;
    position: relative;
    text-align: center;
    color: white;
    box-sizing: border-box;
    padding-top: 10px;
    font-size: 10px;
    margin-bottom: 10px; }
  #header .redes-sociales li a {
    display: block;
    position: absolute;
    top: 0;
    height: 0;
    width: 100%;
    height: 100%; }
  #header .redes-sociales li span {
    display: none; }
  #header .telefonos {
    margin-top: 70px;
    float: right;
    color: #fff;
    margin-right: 20px; }
    @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
      #header .telefonos {
        position: relative;
        float: right;
        margin-right: 0px;
        margin-top: 50px; } }
  #header .telefonos i {
    margin-right: 5px; }
  #header .menu-opener {
    float: right;
    margin-top: 62px;
    margin-left: 20px;
    cursor: pointer; }
    @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
      #header .menu-opener {
        float: none;
        position: absolute;
        margin: 0;
        top: 175px;
        width: 100%; } }
  #header .menu-opener span {
    display: none; }
    @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
      #header .menu-opener span {
        display: inline;
        margin-top: -10px;
        margin-right: 10px;
        font-size: 1.2em;
        color: #fff; } }
  #header .menu-opener i {
    margin: auto;
    font-size: 30px;
    color: #fff; }
    @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
      #header .menu-opener i {
        font-size: 1.2em; } }

/**
* Estilo para footer
* Utiliza variables de  la paleta de colores
*/
#footer {
  position: relative;
  background-color: #333333;
  color: #fff;
  display: flex;
  /**
  * estilos dentro de footer 
  */
  /* columnaas laterales  */
  /* logo en la primer columna */
  /* columna central */
  /* redes sociales */
  /* menu principal */
  /* linea separadora */
  /* 
  * Boton de subir 
  * Se muestra unicamente en narrow(all)
  */ }
  #footer .contenedor {
    position: relative;
    display: flex;
    margin: 40px 0; }
    @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
      #footer .contenedor {
        display: block;
        width: 100%; } }
  #footer .contenedor .columna {
    flex-grow: 2;
    width: 30%; }
    @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
      #footer .contenedor .columna {
        display: block;
        width: 100%; } }
  #footer .footer-logo {
    margin: auto;
    width: 150px;
    position: relative;
    display: flex; }
  #footer .contenedor .columna-2 {
    flex-grow: 1;
    width: 40%;
    margin: auto 20px; }
    @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
      #footer .contenedor .columna-2 {
        text-align: center;
        margin: auto;
        display: block;
        width: 100%; } }
  #footer .columna-2 h3 {
    text-transform: uppercase;
    margin: 10px 0; }
  #footer .redes-sociales ul {
    display: flex;
    flex-wrap: wrap; }
    @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
      #footer .redes-sociales ul {
        text-align: center;
        margin: 0;
        display: block;
        width: 100%; } }
  #footer .redes-sociales li {
    list-style: none;
    width: 32px;
    height: 32px;
    margin-right: 10px;
    margin-top: 10px;
    border: 1px solid #fff;
    border-radius: 50%;
    position: relative;
    text-align: center;
    color: #fff;
    box-sizing: border-box;
    padding-top: 10px;
    font-size: 10px;
    margin-bottom: 10px; }
  #footer .redes-sociales li a {
    display: block;
    position: absolute;
    top: 0;
    height: 0;
    width: 100%;
    height: 100%; }
  #footer .redes-sociales li span {
    display: none; }
  #footer .columna .main-menu ul {
    position: relative;
    margin: 0 40px; }
    @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
      #footer .columna .main-menu ul {
        text-align: center;
        margin: 0;
        display: none;
        width: 100%; } }
  #footer .columna .main-menu ul li {
    list-style: none; }
    #footer .columna .main-menu ul li a {
      line-height: 25px;
      font-size: 1.2em;
      text-transform: uppercase;
      text-decoration: none;
      color: #b7b7b7; }
      #footer .columna .main-menu ul li a:hover {
        color: #fff; }
  #footer .columna .main-menu ul li img {
    display: none; }
  #footer .vertical-line {
    padding: 0 2px;
    border-left: 1px solid #b7b7b7; }
  #footer .subir {
    display: inline-block;
    background-color: #b7b7b7;
    width: 42px;
    height: 42px;
    color: #000;
    font-size: 15px;
    text-align: center;
    /*border: $font-1 solid 1px;*/
    border-radius: 3px;
    position: absolute;
    bottom: 30px;
    right: 30px;
    -webkit-transition: background-color .4s;
    transition: background-color .4s;
    z-index: 30; }
    #footer .subir:hover {
      background-color: #008dc2; }
    #footer .subir span {
      text-decoration: none;
      margin: 13px auto;
      text-align: center; }

/* Scroll suave */
html {
  scroll-behavior: smooth; }

#novedades {
  position: relative;
  width: 100%;
  background-color: #fff;
  line-height: 1.4; }
  #novedades > header {
    width: 100%;
    position: relative;
    height: 180px;
    background-color: #008dc2;
    z-index: 30; }
    #novedades > header .fondo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("/images/fondo_header_site.png"), url("/images/fondos/fondo4_fino.jpg");
      background-size: cover;
      background-position: center 25%;
      z-index: 31; }
    #novedades > header h1 {
      position: absolute;
      top: 70px;
      left: 120px;
      color: #fff;
      font-weight: 300;
      text-transform: uppercase;
      font-size: 40px;
      line-height: 40px;
      text-shadow: 0 0 20px #008dc2,0 0 20px #008dc2;
      z-index: 32; }
      @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
        #novedades > header h1 {
          width: 80%;
          margin: 10%;
          font-size: 30px;
          text-align: center;
          top: 0;
          left: 0; } }

#novedades .list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 50px; }

#novedades .list article {
  width: 320px;
  margin: 30px;
  max-height: 550px;
  overflow: hidden; }

#novedades .list article .fecha {
  width: 100px;
  float: left; }

#novedades .list article header {
  height: 30px; }

#novedades .list article .area {
  width: 200px;
  float: right;
  text-align: right;
  box-sizing: border-box;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #193f69; }

#novedades .list article .area a {
  color: #fff;
  text-decoration: none; }

#novedades .list article .area a:hover {
  color: #aaa; }

#novedades .list article .image_container {
  width: 100%;
  height: auto; }

#novedades .list article .image_container img {
  width: 100%;
  height: 164px;
  object-fit: cover; }

#novedades .list article .titulo {
  clear: both;
  margin-bottom: 10px; }

#novedades .list article .titulo a {
  text-decoration: none;
  color: #6194af;
  font-size: 1.2em;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold; }

#novedades .link-todas {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center; }

#novedades .link-todas a {
  color: #aaa;
  font-size: 1.2em;
  text-decoration: none; }

#novedades .link-todas a:hover {
  color: #777; }

.tabs {
  max-width: 800px;
  margin: 30px auto; }
  .tabs .containerBtn ul {
    display: flex;
    justify-content: center; }
  .tabs .containerBtn li {
    list-style: none;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 10px;
    margin: 0 10px 0 0;
    color: white; }
    .tabs .containerBtn li:nth-child(even) {
      background-color: #01528a; }
    .tabs .containerBtn li:nth-child(odd) {
      background-color: #008dc2; }
    .tabs .containerBtn li.active {
      background-color: #10396d; }
    .tabs .containerBtn li:not(.active) {
      margin-bottom: 3px; }
    .tabs .containerBtn li .title {
      font-size: medium;
      display: inline-block;
      font-weight: bold;
      text-align: center; }
    .tabs .containerBtn li .subtitle {
      font-size: 1.2em;
      font-weight: none;
      text-align: center; }
    .tabs .containerBtn li span {
      display: flex;
      margin: 5px 0 0 0;
      justify-content: center; }
      .tabs .containerBtn li span.fa-chevron-down {
        font-size: 20px;
        margin: 5px;
        -webkit-transition: all 0.5s;
        transition: all 0.5s; }
        .tabs .containerBtn li span.fa-chevron-down.up {
          font-size: 15px;
          -webkit-transform: rotate(180deg);
                  transform: rotate(180deg); }
  .tabs .tabContent {
    max-width: 790px;
    width: 100%;
    background-color: #10396d;
    padding: 10px 0px;
    color: white;
    text-align: center;
    box-sizing: border-box;
    font-size: 22px; }
    .tabs .tabContent.hide {
      background-color: transparent; }

#institucional > header {
  width: 100%;
  position: relative;
  height: 180px;
  background-color: #008dc2;
  z-index: 30; }
  #institucional > header .fondo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("/images/fondo_header_site.png"), url("/images/fondos/fondo4_fino.jpg");
    background-size: cover;
    background-position: center 25%;
    z-index: 31; }
  #institucional > header h1 {
    position: absolute;
    top: 70px;
    left: 120px;
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 40px;
    text-shadow: 0 0 20px #008dc2,0 0 20px #008dc2;
    z-index: 32; }
    @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
      #institucional > header h1 {
        width: 80%;
        margin: 10%;
        font-size: 30px;
        text-align: center;
        top: 0;
        left: 0; } }

#institucional .inst-fila {
  display: inline-flex;
  flex-wrap: wrap;
  flex: 1 1 20%;
  align-items: auto;
  margin: auto;
  align-content: center; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #institucional .inst-fila {
      display: inherit; } }
  #institucional .inst-fila.unica {
    display: flex !important;
    justify-content: center;
    color: white;
    text-align: center; }
    #institucional .inst-fila.unica h3 {
      margin-bottom: 10px; }
    #institucional .inst-fila.unica p {
      margin-bottom: 10px; }

#institucional .inst-card {
  background: #008dc2;
  margin: 10px 10px;
  padding: 10px 50px; }
  #institucional .inst-card h3 {
    font-size: 18px; }
  #institucional .inst-card p {
    font-size: 15px; }

#oferta-academica {
  margin: 20px 0; }
  #oferta-academica .flex-ofertas {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 20%;
    margin: auto;
    align-content: center; }
    @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
      #oferta-academica .flex-ofertas {
        display: inherit; } }
  #oferta-academica .no-result {
    color: #b7b7b7; }
    #oferta-academica .no-result span {
      color: #333; }
  #oferta-academica h2 {
    font-size: 1.8em;
    color: #333;
    border-bottom: 2px solid #333;
    width: 100%;
    padding-bottom: 8px;
    margin: 20px 0;
    clear: both; }

#mostrar-oferta header {
  width: 100%;
  position: relative;
  height: 180px;
  background-color: #008dc2;
  z-index: 30; }
  #mostrar-oferta header .fondo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("/images/fondo_header_site.png"), url("/images/fondos/fondo4_fino.jpg");
    background-size: cover;
    background-position: center 25%;
    z-index: 31; }
  #mostrar-oferta header h1 {
    position: absolute;
    top: 70px;
    left: 120px;
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 40px;
    text-shadow: 0 0 20px #008dc2,0 0 20px #008dc2;
    z-index: 32; }
    @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
      #mostrar-oferta header h1 {
        width: 80%;
        margin: 10%;
        font-size: 30px;
        text-align: center;
        top: 0;
        left: 0; } }

.card {
  margin: 20px auto;
  box-sizing: border-box;
  width: 350px;
  min-height: 180px;
  padding: 15px;
  background-color: #10396d;
  border-radius: 10px;
  position: relative; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    .card {
      width: 80%; } }
  .card h3 {
    text-align: left;
    color: #fff !important;
    margin: 0 !important;
    font-size: 1.2em !important; }
  .card .descripcion {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #ddd; }
  .card .actions {
    display: flex;
    position: absolute;
    bottom: 15px;
    left: 15px; }
  .card .btn {
    background-color: #008dc2;
    border-radius: 8px;
    width: 40px;
    padding: 8px;
    margin: 2px;
    text-align: center; }
    .card .btn a {
      color: #fff; }
  .card .btn:hover {
    background-color: #0079a6; }
  .card .icons {
    position: absolute;
    display: flex;
    bottom: 10px;
    right: 12px; }
    .card .icons.popover__message {
      text-align: center;
      font-weight: 0; }
    .card .icons i {
      color: #ccc;
      cursor: help;
      justify-content: center;
      text-align: center;
      display: inline-flex;
      flex-direction: column;
      font-size: 1em;
      padding: 4px;
      border: 2px solid #008dc2;
      margin: 2px;
      height: 25px;
      width: 25px;
      border-radius: 50%; }
      .card .icons i .popover__content {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        bottom: -30px;
        left: 0px;
        color: #333;
        -webkit-transform: translate(-300px, 0px);
                transform: translate(-300px, 0px);
        background-color: #bfbfbf;
        padding: 3px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
        width: auto; }
        .card .icons i .popover__content p {
          width: 300px; }
      .card .icons i:hover .popover__content {
        z-index: 10;
        opacity: 1;
        visibility: visible;
        -webkit-transform: translate(-200px, 0);
                transform: translate(-200px, 0);
        -webkit-transition: all 0.5s;
        transition: all 0.5s; }
        @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
          .card .icons i:hover .popover__content {
            z-index: 10;
            opacity: 1;
            visibility: visible;
            -webkit-transform: translate(-5%, 0px);
                    transform: translate(-5%, 0px);
            -webkit-transition: all 0.5s;
            transition: all 0.5s; } }
        @media only screen and (min-width: 800px) and (max-width: 1200px) {
          .card .icons i:hover .popover__content {
            z-index: 10;
            opacity: 1;
            visibility: visible;
            -webkit-transform: translate(-5%, 0px);
                    transform: translate(-5%, 0px);
            -webkit-transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
            transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97); } }
      .card .icons i:not(:hover) {
        -webkit-animation-name: card-animation-hidden;
                animation-name: card-animation-hidden;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }

@-webkit-keyframes card-animation-hidden {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.7; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg); } }

@keyframes card-animation-hidden {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.7; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg); } }

.searcher {
  position: absolute;
  bottom: 20px;
  right: 120px;
  display: flex;
  justify-content: end;
  margin-right: 20px;
  z-index: 33;
  /* When the input field gets focus, change its width to 100% */ }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    .searcher {
      bottom: 5px;
      right: 0px; } }
  .searcher p {
    margin-top: 6px;
    font-size: 1.5em;
    margin-right: 20px;
    display: inline-flex;
    z-index: 34;
    color: #fff;
    text-shadow: 3px 3px 15px #008dc2; }
  .searcher input[type=text] {
    float: right;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAACYktHRAD/h4/MvwAAAAl2cEFnAAABKgAAASkAUBZlMQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxMy0wNC0xMFQwNjo1OTowNy0wNzowMI5BiVEAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTMtMDQtMTBUMDY6NTk6MDctMDc6MDD/HDHtAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAABF0RVh0VGl0bGUAc2VhcmNoLWljb27Cg+x9AAACKklEQVQ4T6WUSavqQBCFK+2sII7gShFXLpUsBBHFf+1KcAQFwaWiolsnnBDn++4p0iHRqPDuByFJd/Wp6qrqVn5+IQP3+52m0ymtVis6Ho885na7KRgMUiKR4O9vmEQHgwGNx2NyOp0khCBFUXgcJo/Hg67XK8ViMcpkMjz+Dl200+nQZrMhh8PBE4gYQgDidrudvzEOm2KxyP9WsCginM1mHKEUS6VSFA6HOWI4G41GPAfx2+1GgUCAVFXVZMwovwY/lUqFPB4PiyFn+XxemzbT6/VovV6z8Ol0olwux+LPCBQFEQKIvhME2WyWbWGHFCD/VghUGVvE1rDlb6TTabbFmuVyqY2aEWgbFALeI5GINvyeUCjEtlgju+IZoRWfkS30CURoxFJUNjMEt9stf38CNjJKIFvNiMBJgTebzcZt843hcMhCELWqPBDxeJwulwtvC/3X7/e1qVfgFD0rC5tMJrUZM8Lr9VI0GmVBRDCfz6nZbHI/Sna7HXW7XZpMJtxSiBIP1lmhH9NqtaqfGKQDTmQREBnSgwfmMqfYYblc1o+2xHShtNttLgSiee4EmMEp3hDBPJzikimVSuRyuTTLJ1GwWCz4pCB3UhiL/X4/Hw50C5zjLSM+n898weCogxdRIzAGxigAdtNqtV6EC4UC+Xy+z6Kf2O/31Gg0TMK4ZBDxf4uCw+FA9XpdF0aaUOg/iQLcHbVaTb/p0Cl/FgXIJ/oYnaCqKv0DC6dltH6Ks84AAAAASUVORK5CYII=);
    background-position: 10px 12px;
    background-color: white;
    background-repeat: no-repeat;
    padding: 12px 20px 12px 40px;
    width: 130px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
    border: 1px solid #ccc;
    color: #333;
    border-radius: 10px;
    box-sizing: border-box;
    z-index: 34; }
  .searcher input[type=text]:focus {
    width: 20vw; }
    @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
      .searcher input[type=text]:focus {
        width: 80%; } }

#wp-site-header {
  width: 100%;
  position: relative;
  height: 180px;
  background-color: #008dc2; }

#wp-site-header .fondo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/images/fondo_header_site.png"), url("/images/fondos/fondo4_fino.jpg");
  background-size: cover;
  background-position: center 25%; }

#wp-site-header h1 {
  position: absolute;
  top: 70px;
  left: 120px;
  color: #fff;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 40px;
  line-height: 40px;
  text-shadow: 0 0 20px #008dc2,0 0 20px #008dc2; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #wp-site-header h1 {
      width: 80%;
      margin: 10%;
      font-size: 30px;
      text-align: center;
      top: 0;
      left: 0; } }

#wp-site-header .button-site {
  position: absolute;
  bottom: 10px; }

#wp-site-header .button-site i {
  font-size: 24px;
  line-height: 24px;
  color: #fff; }

#wp-site-header .button-site span {
  display: none; }

#wp-site-header .button-menu {
  left: 20px; }

#wp-site-header .button-sidebar {
  right: 20px; }

#wp-site-header-fixed {
  width: 100%;
  position: fixed;
  top: 60px;
  height: 60px;
  z-index: 45;
  background-color: #008dc2; }

#wp-site-header-fixed .fondo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/images/fondo_header_site.png"), url("/images/fondos/fondo4_fino.jpg");
  background-size: cover;
  background-position: center 45%; }

#wp-site-header-fixed h1 {
  position: absolute;
  top: 10px;
  left: 120px;
  color: #fff;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 40px;
  text-shadow: 0 0 20px #008dc2,0 0 20px #008dc2; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #wp-site-header-fixed h1 {
      width: 80%;
      margin: 10px 10%;
      font-size: 15px;
      text-align: center;
      top: 0;
      left: 0;
      font-weight: bold; } }

#wp-site-header-fixed .button-site {
  position: absolute;
  bottom: 18px; }

#wp-site-header-fixed .button-site i {
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  cursor: pointer; }

#wp-site-header-fixed .button-site span {
  display: none; }

#wp-site-header-fixed .button-menu {
  left: 20px; }

#wp-site-header-fixed .button-sidebar {
  right: 20px; }

#wp-site-menu {
  background-color: #555;
  color: #ddd;
  box-sizing: border-box;
  order: 1;
  flex-shrink: 0;
  -webkit-transition: margin-left 1s;
  transition: margin-left 1s;
  z-index: 40; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 1400px) {
    #wp-site-menu {
      width: 300px; } }
  @media only screen and (min-width: 800px) and (max-width: 1200px) {
    #wp-site-menu {
      width: 180px; } }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #wp-site-menu {
      position: absolute;
      left: 0;
      width: 250px;
      top: 0;
      min-height: 500px;
      -webkit-transition: left 1s;
      transition: left 1s;
      box-shadow: 3px 3px 10px #777; } }

@media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 1400px) {
  #wp-site-menu.closed {
    margin-left: -300px; } }

@media only screen and (min-width: 800px) and (max-width: 1200px) {
  #wp-site-menu.closed {
    margin-left: -180px; } }

@media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
  #wp-site-menu.closed {
    left: -250px; } }

#wp-site-menu nav.menu li {
  list-style: none;
  position: relative;
  border-bottom: 1px solid #777; }

#wp-site-menu nav.menu li a {
  display: block;
  text-decoration: none;
  font-size: 1em;
  color: #ddd;
  padding: 20px; }

#wp-site-menu nav.menu li a.active {
  color: #F1F17D; }

#wp-site-menu nav.menu li > ul {
  background-color: #777; }

#wp-site-menu nav.menu li > ul li {
  display: none; }

#wp-site-menu nav.menu li a.active + ul li {
  display: block; }

#wp-site-menu nav.menu li > ul li {
  border: 0; }

#wp-site-menu nav.menu li > ul > i {
  position: absolute;
  top: 27px;
  font-size: 14px;
  right: 25px;
  color: #999; }

#wp-site-menu nav.menu li > ul a {
  margin: 0;
  padding: 15px;
  font-size: 0.9em;
  padding-left: 50px; }

#wp-site-menu nav.menu li:hover a {
  background-color: #444; }

#wp-site-menu nav.menu li:hover > ul a {
  background-color: #6a6a6a; }

#wp-site-menu nav.menu li .image_container {
  width: 16px;
  position: absolute; }

#wp-site-menu nav.menu li .image_container img {
  width: 100%; }

#wp-site-menu nav.menu li span {
  font-size: 1.2em; }

#wp-site-menu nav.menu li .image_container + span {
  margin-left: 25px; }

#site-archive {
  min-height: 78vh;
  position: relative;
  padding-bottom: 20px; }

#site-archive h1 {
  font-size: 1.8em;
  color: #333;
  border-bottom: 2px solid #333;
  width: 100%;
  padding-bottom: 8px;
  margin: 20px 0;
  clear: both; }

#site-archive .list-pagination {
  float: right; }

#site-archive .list-pagination ul {
  display: flex; }

#site-archive .list-pagination ul li {
  list-style: none;
  margin: 0 5px; }

#site-archive .list-pagination ul li a {
  cursor: pointer; }

#site-archive .list-pagination ul li.disabled a {
  color: #777;
  cursor: not-allowed; }

#site-archive .list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  left: 5%;
  width: 90%;
  margin: auto; }

#site-archive .list article {
  width: 320px;
  margin: 30px;
  max-height: 550px;
  overflow: hidden; }

#site-archive .list article h2 {
  font-size: 1.3em; }

#site-archive .list article .image_container {
  height: 160px;
  margin-bottom: 10px; }

#site-archive .list article .image_container .image {
  height: 160px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

#site-archive .list article .date {
  margin-bottom: 10px;
  text-align: right; }

section#share-buttons {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  margin-bottom: 20px; }

section#share-buttons > div.SocialMediaShareButton > div {
  cursor: pointer; }

#site-post article {
  margin: 30px auto; }
  @media only screen and (min-width: 1400px) {
    #site-post article {
      max-width: 1200px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    #site-post article {
      max-width: 1000px; } }
  @media only screen and (min-width: 800px) and (max-width: 1200px) {
    #site-post article {
      max-width: 800px; } }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #site-post article {
      width: 95%; } }

#site-post .image_container {
  width: 100%;
  height: auto;
  margin-bottom: 5px; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #site-post .image_container {
      width: 100%;
      float: none; } }

#site-post .wp-block-file .wp-block-file__button {
  background: #32373c;
  border-radius: 2em;
  color: #fff;
  font-size: 13px;
  padding: .5em 1em;
  margin: 10px; }

#site-post img {
  max-width: 100%;
  object-fit: contain; }

#site-post .image_container img {
  width: 100%;
  height: auto; }

#site-post .post_content h1 {
  margin: 20px 0;
  font-size: 2.5em; }

#site-post .post_content p {
  margin: 20px 0; }

@media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
  #site-post .post_content p img {
    width: 100%; } }

#site-post .content p img.alignleft,
#site-post .wp-caption.alignleft {
  float: left;
  margin: 10px;
  margin-left: 0; }

#site-post .wp-caption .wp-caption-text {
  font-size: 0.8em;
  color: #999; }

#site-post .content p img.alignright,
#site-post .wp-caption.alignright {
  float: right;
  margin: 10px;
  margin-right: 0; }

#site-post ul {
  clear: both; }

#site-post ul ul {
  margin-top: 15px; }

#site-post ul li {
  list-style: circle;
  margin-left: 20px;
  margin-bottom: 15px;
  list-style-position: inside; }

#site-post blockquote {
  width: 86%;
  padding: 10px 2%;
  border: 1;
  margin: 20px 5%;
  color: #999;
  font-size: 1.3em;
  font-style: italic; }

#site-post .aligncenter {
  text-align: center; }

#site-post #share-buttons {
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: space-between; }

#site-post .wp-block-columns {
  display: flex; }

#site-posts .list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 50px; }

#site-posts .list article {
  width: 320px;
  margin: 30px;
  max-height: 550px;
  overflow: hidden; }

#site-posts .list article .fecha {
  width: 100px;
  float: left; }

#site-posts .list article header {
  height: 30px; }

#site-posts .list article .area {
  width: 200px;
  float: right;
  text-align: right;
  box-sizing: border-box;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #193f69; }

#site-posts .list article .area a {
  color: #fff;
  text-decoration: none; }

#site-posts .list article .area a:hover {
  color: #aaa; }

#site-posts .list article .image_container {
  width: 100%;
  height: auto; }

#site-posts .list article .image_container img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

#site-posts .list article h3 {
  clear: both;
  margin-bottom: 10px; }

#site-posts .list article h3 a {
  text-decoration: none;
  color: #6194af;
  font-size: 1.2em;
  text-align: left;
  font-weight: bold; }

#site-posts .list article .date {
  float: left;
  margin-right: 8px;
  font-weight: bold; }

#curza-site-content,
#wp-site-content {
  width: auto;
  order: 2;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff; }

#curza-site-content #wp-site-content {
  padding: 0; }

#curza-site-content h2,
#wp-site-content h2 {
  font-size: 1.8em;
  color: #333;
  border-bottom: 2px solid #333;
  width: 100%;
  padding-bottom: 8px;
  margin: 20px 0;
  clear: both; }

#curza-site-content h3,
#wp-site-content h3 {
  color: #008dc2;
  margin: 20px 0;
  font-size: 1.5em; }

#site-post {
  position: relative; }

#site-post .post_content {
  margin: auto; }

#site-post article {
  margin: 30px auto;
  line-height: 1.2;
  font-size: 1.1em; }
  @media only screen and (min-width: 1400px) {
    #site-post article {
      max-width: 800px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    #site-post article {
      max-width: 700px; } }
  @media only screen and (min-width: 800px) and (max-width: 1200px) {
    #site-post article {
      max-width: 600px; } }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #site-post article {
      width: 95%; } }

#site-post article .content,
#site-post article .excerpt {
  font-size: 1.1em;
  line-height: 1.3; }

#wp-site-sidebar {
  background-color: #fff;
  color: #333;
  box-sizing: border-box;
  padding: 10px 20px;
  order: 3;
  flex-shrink: 0;
  -webkit-transition: margin-right 1s;
  transition: margin-right 1s;
  z-index: 42; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 1400px) {
    #wp-site-sidebar {
      width: 300px; } }
  @media only screen and (min-width: 800px) and (max-width: 1200px) {
    #wp-site-sidebar {
      width: 180px; } }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #wp-site-sidebar {
      position: absolute;
      right: 0;
      width: 250px;
      top: 0;
      min-height: 500px;
      -webkit-transition: right 1s;
      transition: right 1s;
      box-shadow: -3px 3px 10px #777; } }

@media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 1400px) {
  #wp-site-sidebar.closed {
    margin-right: -300px; } }

@media only screen and (min-width: 800px) and (max-width: 1200px) {
  #wp-site-sidebar.closed {
    margin-right: -180px; } }

@media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
  #wp-site-sidebar.closed {
    right: -250px; } }

#wp-site .wp-site-wrapper {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  max-width: 100vw; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #wp-site .wp-site-wrapper {
      display: block; } }

#site-home {
  max-width: 1200px;
  margin: auto; }

section#libros ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: start; }

section#libros li {
  list-style: none;
  width: 200px;
  margin: 20px; }

section#libros li img {
  width: 100%; }

section#actas .lista-actas h2 {
  border: 0; }

#tramites {
  min-height: 500px; }

section#tramites .box-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: start; }

section#tramites .box {
  list-style: none;
  text-align: center;
  padding: 1em;
  border: solid 3px;
  width: 300px;
  margin: 20px; }

section#tramites li img {
  width: 100%; }

.description {
  margin: 1.5em; }

.btn {
  background-color: #006064;
  border: 0px;
  color: white;
  padding: 1.3em; }

.btn-sm {
  padding: 1em; }

.btn:hover {
  background-color: #0097A7;
  cursor: pointer; }

/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  z-index: 50;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4); }

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: 10% auto;
  padding: 0;
  border: 1px solid #888;
  width: 70%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s; }

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0; }
  to {
    top: 0;
    opacity: 1; } }

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0; }
  to {
    top: 0;
    opacity: 1; } }

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold; }

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer; }

.modal-header {
  padding: 2px 16px;
  background-color: #10396d;
  color: white; }

.modal-body {
  padding: 1.5em;
  font-size: 1.3em; }

.modal-body ul {
  margin: 2em; }

.modal-body p {
  margin-top: 1em; }

.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white; }

section#home-inicio .slider {
  position: absolute;
  top: 150px;
  left: 0;
  width: 100%;
  bottom: 0;
  z-index: 20; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    section#home-inicio .slider {
      top: 165px; } }

section#home-inicio .slider .list {
  display: none; }

section#home-inicio .slider .page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    section#home-inicio .slider .page {
      width: 100%; } }

section#home-inicio .slider .page article .image_container .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 25;
  background-position: 75% center;
  background-size: cover;
  opacity: 0.5; }

section#home-inicio .slider .page article .post_content {
  position: absolute;
  top: 20vh;
  left: 0;
  width: 40%;
  height: auto;
  z-index: 30;
  margin: 0;
  padding: 10px 3%;
  padding-top: 20px;
  text-align: center;
  box-sizing: border-box; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    section#home-inicio .slider .page article .post_content {
      top: 10px;
      width: 100%;
      max-height: 40vh;
      overflow: hidden; } }

section#home-inicio .slider .page article.mode1 .post_content {
  background-color: rgba(0, 0, 0, 0.5); }

section#home-inicio .slider .page article.mode2 .post_content {
  padding: 0;
  top: 8%;
  left: 8%;
  width: 50%;
  height: 90%; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    section#home-inicio .slider .page article.mode2 .post_content {
      top: 5%;
      left: 5%;
      width: 90%;
      height: 90%; } }

section#home-inicio .slider .page article.mode2 .post_content h2 {
  display: none; }

section#home-inicio .slider .page article .post_content h2 a {
  color: #fff;
  font-size: 1.2em; }

section#home-inicio .slider .page article .post_content .date {
  display: none; }

section#home-inicio .slider .page article.mode1 .post_content .content {
  display: none; }

section#home-inicio .slider .page article.mode2 .post_content .content img {
  display: block;
  position: absolute;
  width: 100%;
  height: 80%;
  object-fit: contain;
  -webkit-filter: drop-shadow(3px 3px 20px #091f3c);
          filter: drop-shadow(3px 3px 20px #091f3c); }

section#home-inicio .slider .page article .post_content .excerpt {
  margin: 20px;
  color: #ccc;
  font-size: 1.1em;
  font-style: italic; }

section#home-inicio .slider .page article.mode2 .post_content .excerpt {
  display: none; }

section#home-inicio .slider .ver-todas {
  display: none; }

section#home-inicio .slider .page article .post_content .content .wp-block-button a {
  display: inline-block;
  background-color: #008dc2;
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  color: #fff; }

#home-header {
  position: absolute;
  width: 100%;
  top: 0;
  height: 150px; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-header {
      height: 165px; } }

#home-header > .fondo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #10396d;
  opacity: 0.6;
  z-index: 30; }

#home-header .wrapper-central {
  z-index: 35; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-header .wrapper-central {
      text-align: center; } }

#home-header .logo {
  position: absolute;
  top: 32px;
  height: 80px;
  width: 450px; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-header .logo {
      position: relative;
      top: 12px;
      width: 342px;
      margin: auto; } }

#home-header .logo img {
  height: 100%;
  width: auto;
  float: left;
  margin-right: 10px; }

#home-header .logo .logo-text {
  color: #fff;
  margin-top: 5px; }

#home-header .logo .logo-text .titulo {
  font-size: 1.5em;
  font-weight: bold;
  color: #fff; }

#home-header .logo .logo-text .descripcion {
  color: #fff; }

#home-header .logo .logo-text .unco {
  color: #fff; }

#home-header .wrapper-servicios {
  float: right; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-header .wrapper-servicios {
      width: 100%;
      margin: auto;
      float: none; } }

#home-header .redes-sociales {
  float: right;
  margin-top: 60px; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-header .redes-sociales {
      position: relative;
      margin-top: 30px;
      float: left;
      max-width: 156px;
      margin-left: 5px; } }

#home-header .redes-sociales ul {
  display: flex;
  flex-wrap: wrap; }

#home-header .redes-sociales li {
  list-style: none;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  border: 1px solid #fff;
  border-radius: 50%;
  position: relative;
  text-align: center;
  color: white;
  box-sizing: border-box;
  padding-top: 10px;
  font-size: 10px;
  margin-bottom: 10px; }

#home-header .redes-sociales li a {
  display: block;
  position: absolute;
  top: 0;
  height: 0;
  width: 100%;
  height: 100%; }

#home-header .redes-sociales li span {
  display: none; }

#home-header .telefonos {
  margin-top: 70px;
  float: right;
  color: #fff;
  margin-right: 20px; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-header .telefonos {
      position: relative;
      float: right;
      margin-right: 5px;
      margin-top: 38px; } }

#home-header .telefonos i {
  margin-right: 5px; }

#home-inicio .notificacion {
  position: absolute;
  top: 150px;
  left: 0;
  right: 0;
  width: 500px;
  margin: auto;
  text-align: center;
  background-color: #aa0000;
  z-index: 30;
  padding: 10px;
  border-radius: 0 0 10px 10px;
  cursor: pointer;
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
  display: none; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-inicio .notificacion {
      width: 100%;
      border-radius: 0;
      padding: 5px;
      top: 160px; } }

#home-inicio .notificacion:hover {
  background-color: #cc0000; }

#home-inicio .notificacion a {
  color: #fff; }

#home-inicio-menu {
  position: absolute;
  bottom: 10vh;
  right: 10%;
  width: 30%;
  height: auto;
  z-index: 30;
  color: #fff;
  font-size: 1.3em;
  background: rgba(0, 0, 0, 0.4); }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-inicio-menu {
      width: auto;
      top: 60vh;
      bottom: 15px;
      left: 2vw;
      right: 2vw; } }

@media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
  #home-inicio-menu nav.menu > ul {
    display: flex;
    flex-wrap: wrap; } }

#home-inicio-menu nav.menu li {
  margin: 0;
  padding: 14px;
  list-style: none;
  text-align: right;
  background: transparent;
  -webkit-transition: 0.5s all;
  transition: 0.5s all; }
  @media only screen and (min-width: 800px) and (max-width: 1200px), only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 1400px) {
    #home-inicio-menu nav.menu li {
      padding: 1.2vh; } }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-inicio-menu nav.menu li {
      margin: 15px 1%;
      padding: 0;
      width: 48%;
      text-align: center;
      box-sizing: border-box; } }

#home-inicio-menu nav.menu li .image_wrapper {
  display: none; }

#home-inicio-menu nav.menu li.submenu-active {
  background-color: #10396d; }

#home-inicio-menu nav.menu li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5em;
  font-weight: bold; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 800px) and (max-width: 1200px) {
    #home-inicio-menu nav.menu li a {
      font-size: 2.5vh; } }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-inicio-menu nav.menu li a {
      font-size: 1.2em; } }

#home-inicio-menu nav.menu li a:hover {
  font-style: italic;
  color: #ccc; }

#home-inicio-menu nav.menu li a.active {
  color: #00ffff; }

.megamenu .close-menu-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 30px;
  z-index: 110; }

.megamenu .close-menu-btn > div {
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer; }

.megamenu .close-menu-btn i {
  color: #fff;
  font-size: 24px; }

.megamenu .close-menu-btn > div:hover i {
  color: #aaa; }

#home-megamenu.initial {
  left: -60vw; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-megamenu.initial {
      left: -100vw; } }

#home-megamenu.opened {
  left: 0; }

#home-megamenu.closed {
  left: -60vw; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-megamenu.closed {
      left: -100vw; } }

#home-megamenu {
  position: absolute;
  top: 150px;
  left: -60vw;
  width: 60vw;
  bottom: 0;
  z-index: 32;
  overflow: hidden;
  -webkit-transition: left 1s;
  transition: left 1s; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-megamenu {
      top: 175px;
      left: -100vw;
      width: 100vw; } }

#home-megamenu .fondo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #10396d;
  z-index: 101; }

#home-megamenu .img_fondo {
  position: absolute;
  z-index: 102;
  top: 30%;
  height: 30%;
  width: auto;
  left: 80px;
  opacity: 0.4; }

#home-megamenu .img_fondo img {
  height: 100%; }

#home-megamenu .submenu {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: 80%;
  z-index: 102;
  overflow: auto; }

#home-megamenu li {
  list-style: none;
  color: #fff; }

#home-megamenu li.nivel-1 {
  width: 80%;
  margin: 2%;
  text-align: right;
  flex-grow: 1;
  padding-top: 2vh; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-megamenu li.nivel-1 {
      width: 96%;
      text-align: center; } }

#home-megamenu li.nivel-1 > a > span {
  font-weight: bold;
  color: #fff;
  font-size: 1.5em; }

#home-megamenu li.nivel-1 > a:hover > span {
  color: #aaa; }

#home-megamenu li.nivel-1 > .submenu {
  display: block;
  height: 80%;
  padding: 10% 5%;
  width: 90%; }

#home-megamenu li.nivel-2 {
  width: 100%;
  text-align: left;
  height: 5vh; }

#home-megamenu li.nivel-2 > a > span {
  color: #333; }

#home-megamenu li.nivel-2 > a:hover > span {
  color: #777; }

#home-inicio {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #10396d; }

#home-inicio #scroll-proposal {
  z-index: 90;
  position: absolute;
  bottom: 10px;
  width: 100%;
  height: 40px;
  text-align: center; }

#home-inicio #scroll-proposal i {
  color: #fff;
  font-size: 40px;
  margin: auto;
  cursor: pointer; }

#home-accesos {
  position: relative;
  width: 100%;
  background-color: #fff; }

#home-accesos h1 {
  font-size: 2.5em;
  color: #333;
  font-weight: normal;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-top: 5px;
  border-top: 1px solid #333; }

#home-accesos nav.menu ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-accesos nav.menu ul {
      display: block; } }

#home-accesos nav.menu ul li {
  width: 21%;
  height: 100px;
  margin: 2%;
  list-style: none;
  position: relative; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-accesos nav.menu ul li {
      max-width: 90%;
      margin: 30px auto;
      height: auto; } }

#home-accesos nav.menu ul li .image_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 100%;
  display: flex;
  align-content: center; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-accesos nav.menu ul li .image_wrapper {
      position: relative; } }

#home-accesos nav.menu ul li .image_wrapper img {
  max-width: 90%;
  max-height: 90%;
  margin: auto;
  vertical-align: middle; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-accesos nav.menu ul li .image_wrapper img {
      width: 90%;
      height: auto;
      max-height: none; } }

#home-accesos nav.menu ul li span {
  display: none; }

#buscar-carreras {
  position: relative;
  width: 100%;
  background-color: #ccc;
  display: flex;
  text-align: center;
  padding-top: 40px; }
  #buscar-carreras h1 {
    font-size: 2.5em;
    color: #333;
    font-weight: normal;
    margin-bottom: 20px;
    padding-top: 5px;
    border-top: 1px solid #333;
    text-align: left; }
  #buscar-carreras input {
    background-color: #eee;
    font-size: 1.2em;
    line-height: 10px;
    border: 2px solid #008dc2;
    border-radius: 10px;
    justify-content: center;
    margin: 10px;
    width: 30vw;
    padding: 12px;
    color: #555; }
    @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
      #buscar-carreras input {
        width: 50vw; } }
  #buscar-carreras .button {
    background-color: #10396d;
    padding: 8px 8px;
    color: #fff;
    display: block;
    width: 80px;
    margin: auto;
    border-radius: 10px;
    margin: 20px auto; }

#home-novedades {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding-top: 40px; }

#home-novedades h1 {
  font-size: 2.5em;
  color: #333;
  font-weight: normal;
  margin-bottom: 20px;
  padding-top: 5px;
  border-top: 1px solid #333;
  text-align: left; }

#home-novedades .list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
  padding-bottom: 60px; }

#home-novedades .list article {
  width: 280px;
  margin: 10px;
  overflow: hidden; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-novedades .list article {
      margin: 5%;
      width: 90%; } }

#home-novedades .list article .fecha {
  width: 80px;
  float: left; }

#home-novedades .list article header {
  height: 30px; }

#home-novedades .list article .area {
  width: 180px;
  float: right;
  text-align: right;
  box-sizing: border-box;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #193f69;
  font-size: 0.9em; }

#home-novedades .list article .area a {
  color: #fff;
  text-decoration: none; }

#home-novedades .list article .area a:hover {
  color: #aaa; }

#home-novedades .list article .image_container {
  margin: 10px auto; }

#home-novedades .list article .image_container .image_wrapper {
  width: 100%;
  height: auto; }

#home-novedades .list article .image_container img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

#home-novedades .list article .titulo {
  clear: both;
  margin-bottom: 10px; }

#home-novedades .list article .titulo a {
  text-decoration: none;
  color: #6194af;
  font-size: 1.1em;
  text-align: left;
  font-weight: bold; }

#home-novedades .link-todas {
  width: 100%;
  text-align: center;
  height: 5em; }

#home-novedades .link-todas a {
  color: #fff;
  background: #263238;
  padding: 1.5em;
  font-size: 1.2em;
  text-decoration: none; }

#home-novedades .link-todas a:hover {
  background: #37474F; }

#home-departamentos {
  position: relative;
  width: 100%;
  padding: 40px 0;
  background-color: #999; }

#home-departamentos h1 {
  font-size: 2.5em;
  color: #fff;
  font-weight: normal;
  margin-bottom: 20px;
  padding-top: 5px;
  border-top: 1px solid #fff;
  text-align: left; }

#home-departamentos .fondo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/images/fondos/fondo4.jpg");
  background-position: center;
  background-size: cover;
  z-index: 40;
  opacity: 0.2; }

#home-departamentos .wrapper-central {
  z-index: 41; }

#home-departamentos ul {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  align-items: stretch; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-departamentos ul {
      display: block;
      width: 100%; } }

#home-departamentos ul li {
  flex: 20% 1;
  margin: 1px;
  height: 80px;
  list-style: none;
  box-sizing: border-box;
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
  z-index: 30; }
  #home-departamentos ul li.azul-claro {
    background-color: #008dc2; }
  #home-departamentos ul li.azul-medio {
    background-color: #01528a; }
  #home-departamentos ul li.azul-oscuro {
    background-color: #10396d; }
  #home-departamentos ul li:hover {
    z-index: 31;
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

#home-departamentos ul li a {
  text-decoration: none;
  display: flex;
  height: 100%; }

#home-departamentos ul li span {
  font-size: 1.2em;
  color: white;
  margin: auto;
  display: table-cell;
  vertical-align: middle;
  text-align: center; }

#home-agenda {
  position: relative;
  width: 100%;
  padding: 40px 0;
  background-color: #6194af; }

#home-agenda h1 {
  font-size: 2.5em;
  color: #333;
  font-weight: normal;
  margin-bottom: 20px;
  padding-top: 5px;
  border-top: 1px solid #333;
  text-align: left; }

#home-agenda .list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
  padding-bottom: 60px; }

#home-agenda .list article {
  position: relative;
  width: 450px;
  margin: 20px;
  overflow: hidden; }
  @media only screen and (min-width: 800px) and (max-width: 1200px), only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-agenda .list article {
      margin: 5%;
      width: 90%; } }

#home-agenda .list article .fecha {
  width: 80px;
  float: left;
  color: #333;
  font-weight: bold;
  padding-top: 3px; }

#home-agenda .list article header {
  height: 30px; }

#home-agenda .list article .area {
  width: 350px;
  float: right;
  text-align: right;
  box-sizing: border-box;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 0.9em; }

#home-agenda .list article .area a {
  color: #193f69;
  text-decoration: none;
  font-weight: bold; }

#home-agenda .list article .area a:hover {
  color: #333; }

#home-agenda .list article .image_container {
  width: 160px;
  height: 84px; }

#home-agenda .list article .image_container .image_wrapper {
  width: 100%;
  height: 100%;
  object-fit: cover; }

#home-agenda .list article .image_container img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

#home-agenda .list article .titulo {
  position: absolute;
  top: 25px;
  left: 180px;
  clear: both;
  margin-bottom: 10px; }

#home-agenda .list article .titulo a {
  text-decoration: none;
  color: #fff;
  font-size: 0.75em;
  text-align: left;
  font-weight: bold; }

#home-agenda .link-todas {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center; }

#home-agenda .link-todas a {
  color: #aaa;
  font-size: 1.2em;
  text-decoration: none; }

#home-agenda .link-todas a:hover {
  color: #777; }

#home-plano {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 40px 0; }

#home-plano h1 {
  font-size: 2.5em;
  color: #333;
  font-weight: normal;
  margin-bottom: 20px;
  padding-top: 5px;
  border-top: 1px solid #333; }

#home-plano nav.menu ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-plano nav.menu ul {
      display: block; } }

#home-plano nav.menu ul li {
  width: 220px;
  height: 100px;
  margin: 10px;
  list-style: none;
  position: relative; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-plano nav.menu ul li {
      max-width: 90%;
      margin: 30px auto;
      height: auto; } }

#home-plano nav.menu ul li .image_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 100%;
  display: flex;
  align-content: center; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-plano nav.menu ul li .image_wrapper {
      position: relative; } }

#home-plano nav.menu ul li .image_wrapper img {
  max-width: 90%;
  max-height: 90%;
  margin: auto;
  vertical-align: middle; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #home-plano nav.menu ul li .image_wrapper img {
      width: 90%;
      height: auto;
      max-height: none; } }

#home-plano nav.menu ul li span {
  display: none; }

.image-container {
  position: relative;
  display: inline-block; }

.text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  /* Background color of the text overlay */
  color: white;
  /* Text color */
  text-align: center;
  font-size: 2em;
  font-weight: bold; }

.image-container:hover .text-overlay {
  display: flex;
  align-items: center;
  justify-content: center; }

#plano-curza {
  width: 100%; }

#home-separador {
  position: relative;
  height: 200px;
  width: 100%;
  padding: 40px 0; }

#home-separador .fondo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/images/fondos/fondo5.jpg");
  background-attachment: fixed;
  background-position: top;
  background-size: cover;
  z-index: 40;
  opacity: 1; }

#carreras-departamento {
  max-width: 1200px;
  margin: auto; }

#carreras-departamento ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; }

#carreras-departamento ul li {
  width: 300px;
  height: auto;
  background-color: #10396d;
  list-style: none;
  margin: 5px;
  padding: 20px;
  color: #fff;
  border-radius: 8px; }

#carreras-departamento ul li a {
  color: #fff; }

#carreras-departamento ul li a:hover {
  color: #9ee5ff; }

#curza-carrera {
  position: relative; }
  #curza-carrera .planes {
    float: right; }
    #curza-carrera .planes span {
      float: left;
      padding: 5px; }
    #curza-carrera .planes ul {
      float: left;
      display: flex;
      flex-wrap: nowrap; }
      #curza-carrera .planes ul div {
        margin-left: 15px; }
      #curza-carrera .planes ul li {
        list-style: none;
        cursor: pointer;
        padding: 5px; }
        #curza-carrera .planes ul li.active {
          background-color: #ccc;
          padding: 5px;
          border-radius: 5px; }
    #curza-carrera .planes .plan-item {
      text-align: center; }
    #curza-carrera .planes .display-plan {
      display: block;
      text-align: center;
      font-size: 1.3em;
      padding-top: 5px; }
    #curza-carrera .planes .no-display-plan {
      display: none; }
  #curza-carrera .folleto {
    float: left;
    color: white; }
    #curza-carrera .folleto a {
      color: white; }
  #curza-carrera .info-carrera {
    display: inline-block;
    text-align: justify; }
    #curza-carrera .info-carrera p {
      margin: 20px 0; }
      #curza-carrera .info-carrera p span {
        font-weight: bold; }
      #curza-carrera .info-carrera p .modalidad {
        margin-left: 20px; }
  #curza-carrera .content-tabs {
    font-size: .7em;
    margin: 15px 15px; }
  #curza-carrera div {
    box-sizing: border-box; }
  #curza-carrera .table-container {
    display: block;
    margin: 2em auto;
    width: 90%;
    max-width: 600px;
    overflow: hidden; }
  #curza-carrera .flag-icon {
    margin-right: 0.1em; }
  #curza-carrera .flex-table {
    display: flex;
    flex-flow: row wrap;
    border-left: solid 1px #d9d9d9;
    -webkit-transition: 0.5s;
    transition: 0.5s; }
    #curza-carrera .flex-table:first-of-type {
      border-top: solid 1px #1565C0;
      border-left: solid 1px #1565C0; }
    #curza-carrera .flex-table:first-of-type .flex-row {
      background: #1976D2;
      color: white;
      border-color: #1565C0; }
    #curza-carrera .flex-table.row.fin-ano {
      border-bottom: 2px solid black; }
    #curza-carrera .flex-table.row:nth-child(odd) .flex-row {
      background: #f4f2f1; }
    #curza-carrera .flex-table:hover {
      background: #F5F5F5;
      -webkit-transition: 500ms;
      transition: 500ms; }
  #curza-carrera .flex-row {
    width: calc(100% / 6);
    text-align: center;
    padding: 0.5em 0.5em;
    border-right: solid 1px #d9d9d9;
    border-bottom: solid 1px #d9d9d9; }
  #curza-carrera .rowspan {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center; }
  #curza-carrera .column {
    display: flex;
    flex-flow: column wrap;
    width: 83%;
    padding: 0; }
    #curza-carrera .column .flex-row {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      padding: 0;
      border: 0;
      border-bottom: solid 1px #d9d9d9; }
      #curza-carrera .column .flex-row:hover {
        background: #F5F5F5;
        -webkit-transition: 500ms;
        transition: 500ms; }
  #curza-carrera .flex-cell {
    width: calc(100% / 5);
    text-align: center;
    padding: 0.5em 0.5em;
    border-right: solid 1px #d9d9d9; }
    #curza-carrera .flex-cell .clickPopover {
      color: #1976D2;
      font-size: 1.5em; }
    #curza-carrera .flex-cell .popover__content {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      left: 0px;
      max-width: 500px;
      -webkit-transform: translate(-300px, 0px);
              transform: translate(-300px, 0px);
      background-color: #bfbfbf;
      padding: 1.5rem;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
      width: auto; }
    #curza-carrera .flex-cell:hover .popover__content {
      z-index: 10;
      opacity: 1;
      visibility: visible;
      -webkit-transform: translate(-30%, 0px);
              transform: translate(-30%, 0px);
      -webkit-transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
      transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97); }
      @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
        #curza-carrera .flex-cell:hover .popover__content {
          z-index: 10;
          opacity: 1;
          visibility: visible;
          -webkit-transform: translate(-5%, 0px);
                  transform: translate(-5%, 0px);
          -webkit-transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
          transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97); } }
      @media only screen and (min-width: 800px) and (max-width: 1200px) {
        #curza-carrera .flex-cell:hover .popover__content {
          z-index: 10;
          opacity: 1;
          visibility: visible;
          -webkit-transform: translate(-5%, 0px);
                  transform: translate(-5%, 0px);
          -webkit-transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
          transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97); } }
  @media all and (max-width: 767px) {
    #curza-carrera .flex-table .flex-row {
      border-bottom: 0; }
    #curza-carrera .flex-table .flex-row:last-of-type {
      border-bottom: solid 1px #d9d9d9; }
    #curza-carrera .header .flex-row {
      border-bottom: solid 1px; }
    #curza-carrera .flex-row {
      width: 100%; }
      #curza-carrera .flex-row.first {
        width: 100%;
        border-bottom: solid 1px #d9d9d9; }
    #curza-carrera .column {
      width: 100%; }
      #curza-carrera .column .flex-row {
        border-bottom: solid 1px #d9d9d9; }
    #curza-carrera .flex-cell {
      width: 100%; } }

.megamenu .close-menu-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 30px;
  z-index: 110; }

.megamenu .close-menu-btn > div {
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer; }

.megamenu .close-menu-btn i {
  color: #fff;
  font-size: 24px; }

.megamenu .close-menu-btn > div:hover i {
  color: #aaa; }

#main-megamenu.initial {
  left: -60vw; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #main-megamenu.initial {
      left: -100vw; } }

#main-megamenu.opened {
  left: 0; }

#main-megamenu.closed {
  left: -60vw; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #main-megamenu.closed {
      left: -100vw; } }

#main-megamenu {
  position: fixed;
  left: -60vw;
  width: 60vw;
  bottom: 0;
  z-index: 100;
  overflow: hidden;
  -webkit-transition: left 1s;
  transition: left 1s; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #main-megamenu {
      top: 175px;
      left: -100vw;
      width: 100vw; } }

#main-megamenu.arriba {
  top: 60px; }

#main-megamenu.abajo {
  top: 150px; }

#main-megamenu .fondo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #10396d;
  z-index: 101; }

#main-megamenu .submenu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 102; }

#main-megamenu li {
  list-style: none;
  color: #fff; }

#main-megamenu li.nivel-1 {
  width: 80%;
  margin: 2%;
  text-align: right;
  flex-grow: 1;
  padding-top: 2vh; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #main-megamenu li.nivel-1 {
      width: 96%;
      text-align: center; } }

#main-megamenu li.nivel-1 > a > span {
  font-weight: bold;
  color: #fff;
  font-size: 1.5em; }

#main-megamenu li.nivel-1 > a:hover > span {
  color: #aaa; }

#main-megamenu li.nivel-1 > .submenu {
  display: block;
  height: 80%;
  padding: 10% 5%;
  width: 90%; }

#main-megamenu li.nivel-2 {
  width: 100%;
  text-align: left;
  height: 5vh; }

#main-megamenu li.nivel-2 > a > span {
  color: #333; }

#main-megamenu li.nivel-2 > a:hover > span {
  color: #777; }

/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * Colors
 */
/**
 * Often re-used variables
 */
/**
 * Grid System.
 * https://make.wordpress.org/design/2019/10/31/proposal-a-consistent-spacing-system-for-wordpress/
 */
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Button states and focus styles
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
.wp-block-audio figcaption {
  margin-top: 0.5em;
  margin-bottom: 1em; }

.wp-block-audio audio {
  width: 100%;
  min-width: 300px; }

.wp-block-button {
  color: #fff; }

.wp-block-button.aligncenter {
  text-align: center; }

.wp-block-button.alignright {
  /*rtl:ignore*/
  text-align: right; }

.wp-block-button__link {
  background-color: #32373c;
  border: none;
  border-radius: 28px;
  box-shadow: none;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  margin: 0;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  overflow-wrap: break-word; }

.wp-block-button__link:hover, .wp-block-button__link:focus, .wp-block-button__link:active, .wp-block-button__link:visited {
  color: inherit; }

.wp-gs .wp-block-button__link:not(.has-background) {
  background-color: #32373c;
  background-color: var(--wp-block-core-button--color--background, var(--wp-color--primary, #32373c)); }

.is-style-squared .wp-block-button__link {
  border-radius: 0; }

.no-border-radius.wp-block-button__link {
  border-radius: 0 !important; }

.is-style-outline {
  color: #32373c; }

.is-style-outline .wp-block-button__link {
  background-color: transparent;
  border: 2px solid; }

.wp-block-buttons .wp-block-button {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px; }

.wp-block-buttons.alignright .wp-block-button {
  margin-right: none;
  margin-left: 8px; }

.wp-block-buttons.aligncenter {
  text-align: center; }

.wp-block-calendar {
  text-align: center; }

.wp-block-calendar th,
.wp-block-calendar tbody td {
  padding: 4px;
  border: 1px solid #e2e4e7; }

.wp-block-calendar tfoot td {
  border: none; }

.wp-block-calendar table {
  width: 100%;
  border-collapse: collapse;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; }

.wp-block-calendar table th {
  font-weight: 400;
  background: #edeff0; }

.wp-block-calendar a {
  text-decoration: underline; }

.wp-block-calendar tfoot a {
  color: #00739c; }

.wp-block-calendar table tbody,
.wp-block-calendar table caption {
  color: #40464d; }

.wp-block-categories.alignleft {
  /*rtl:ignore*/
  margin-right: 2em; }

.wp-block-categories.alignright {
  /*rtl:ignore*/
  margin-left: 2em; }

.wp-block-columns {
  display: flex;
  margin-bottom: 28px;
  flex-wrap: wrap; }

@media (min-width: 782px) {
  .wp-block-columns {
    flex-wrap: nowrap; } }

.wp-block-columns.has-background {
  padding: 20px 30px; }

.wp-block-column {
  flex-grow: 1;
  min-width: 0;
  word-break: break-word;
  overflow-wrap: break-word; }

@media (max-width: 599px) {
  .wp-block-column {
    flex-basis: 100% !important; } }

@media (min-width: 600px) and (max-width: 781px) {
  .wp-block-column {
    flex-basis: calc(50% - 16px) !important;
    flex-grow: 0; }
  .wp-block-column:nth-child(even) {
    margin-left: 32px; } }

@media (min-width: 782px) {
  .wp-block-column {
    flex-basis: 0;
    flex-grow: 1; }
  .wp-block-column[style] {
    flex-grow: 0; }
  .wp-block-column:not(:first-child) {
    margin-left: 32px; } }

/**
   * All Columns Alignment
   */
.wp-block-columns.are-vertically-aligned-top {
  align-items: flex-start; }

.wp-block-columns.are-vertically-aligned-center {
  align-items: center; }

.wp-block-columns.are-vertically-aligned-bottom {
  align-items: flex-end; }

/**
   * Individual Column Alignment
   */
.wp-block-column.is-vertically-aligned-top {
  align-self: flex-start; }

.wp-block-column.is-vertically-aligned-center {
  align-self: center; }

.wp-block-column.is-vertically-aligned-bottom {
  align-self: flex-end; }

.wp-block-column.is-vertically-aligned-top, .wp-block-column.is-vertically-aligned-center, .wp-block-column.is-vertically-aligned-bottom {
  width: 100%; }

.wp-block-cover-image,
.wp-block-cover {
  position: relative;
  background-color: #000;
  background-size: cover;
  background-position: center center;
  min-height: 430px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; }

.wp-block-cover-image.has-parallax,
.wp-block-cover.has-parallax {
  background-attachment: fixed; }

@supports (-webkit-overflow-scrolling: touch) {
  .wp-block-cover-image.has-parallax,
  .wp-block-cover.has-parallax {
    background-attachment: scroll; } }

@media (prefers-reduced-motion: reduce) {
  .wp-block-cover-image.has-parallax,
  .wp-block-cover.has-parallax {
    background-attachment: scroll; } }

.wp-block-cover-image.has-background-dim::before,
.wp-block-cover.has-background-dim::before {
  content: "";
  background-color: inherit; }

.wp-block-cover-image.has-background-dim:not(.has-background-gradient)::before,
.wp-block-cover-image .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim:not(.has-background-gradient)::before,
.wp-block-cover .wp-block-cover__gradient-background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1; }

.wp-block-cover-image.has-background-dim:not(.has-background-gradient)::before,
.wp-block-cover-image .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim:not(.has-background-gradient)::before,
.wp-block-cover .wp-block-cover__gradient-background {
  opacity: 0.5; }

.wp-block-cover-image.has-background-dim.has-background-dim-10:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-10:not(.has-background-gradient)::before {
  opacity: 0.1; }

.wp-block-cover-image.has-background-dim.has-background-dim-10 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-10 .wp-block-cover__gradient-background {
  opacity: 0.1; }

.wp-block-cover-image.has-background-dim.has-background-dim-20:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-20:not(.has-background-gradient)::before {
  opacity: 0.2; }

.wp-block-cover-image.has-background-dim.has-background-dim-20 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-20 .wp-block-cover__gradient-background {
  opacity: 0.2; }

.wp-block-cover-image.has-background-dim.has-background-dim-30:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-30:not(.has-background-gradient)::before {
  opacity: 0.3; }

.wp-block-cover-image.has-background-dim.has-background-dim-30 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-30 .wp-block-cover__gradient-background {
  opacity: 0.3; }

.wp-block-cover-image.has-background-dim.has-background-dim-40:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-40:not(.has-background-gradient)::before {
  opacity: 0.4; }

.wp-block-cover-image.has-background-dim.has-background-dim-40 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-40 .wp-block-cover__gradient-background {
  opacity: 0.4; }

.wp-block-cover-image.has-background-dim.has-background-dim-50:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-50:not(.has-background-gradient)::before {
  opacity: 0.5; }

.wp-block-cover-image.has-background-dim.has-background-dim-50 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-50 .wp-block-cover__gradient-background {
  opacity: 0.5; }

.wp-block-cover-image.has-background-dim.has-background-dim-60:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-60:not(.has-background-gradient)::before {
  opacity: 0.6; }

.wp-block-cover-image.has-background-dim.has-background-dim-60 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-60 .wp-block-cover__gradient-background {
  opacity: 0.6; }

.wp-block-cover-image.has-background-dim.has-background-dim-70:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-70:not(.has-background-gradient)::before {
  opacity: 0.7; }

.wp-block-cover-image.has-background-dim.has-background-dim-70 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-70 .wp-block-cover__gradient-background {
  opacity: 0.7; }

.wp-block-cover-image.has-background-dim.has-background-dim-80:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-80:not(.has-background-gradient)::before {
  opacity: 0.8; }

.wp-block-cover-image.has-background-dim.has-background-dim-80 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-80 .wp-block-cover__gradient-background {
  opacity: 0.8; }

.wp-block-cover-image.has-background-dim.has-background-dim-90:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-90:not(.has-background-gradient)::before {
  opacity: 0.9; }

.wp-block-cover-image.has-background-dim.has-background-dim-90 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-90 .wp-block-cover__gradient-background {
  opacity: 0.9; }

.wp-block-cover-image.has-background-dim.has-background-dim-100:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-100:not(.has-background-gradient)::before {
  opacity: 1; }

.wp-block-cover-image.has-background-dim.has-background-dim-100 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-100 .wp-block-cover__gradient-background {
  opacity: 1; }

.wp-block-cover-image.alignleft, .wp-block-cover-image.alignright,
.wp-block-cover.alignleft,
.wp-block-cover.alignright {
  max-width: 290px;
  width: 100%; }

.wp-block-cover-image::after,
.wp-block-cover::after {
  display: block;
  content: "";
  font-size: 0;
  min-height: inherit; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .wp-block-cover-image::after,
  .wp-block-cover::after {
    content: none; } }

.wp-block-cover-image.aligncenter, .wp-block-cover-image.alignleft, .wp-block-cover-image.alignright,
.wp-block-cover.aligncenter,
.wp-block-cover.alignleft,
.wp-block-cover.alignright {
  display: flex; }

.wp-block-cover-image .wp-block-cover__inner-container,
.wp-block-cover .wp-block-cover__inner-container {
  width: calc(100% - 70px);
  z-index: 1;
  color: #f8f9f9; }

.wp-block-cover-image p:not(.has-text-color),
.wp-block-cover-image h1:not(.has-text-color),
.wp-block-cover-image h2:not(.has-text-color),
.wp-block-cover-image h3:not(.has-text-color),
.wp-block-cover-image h4:not(.has-text-color),
.wp-block-cover-image h5:not(.has-text-color),
.wp-block-cover-image h6:not(.has-text-color),
.wp-block-cover-image .wp-block-subhead:not(.has-text-color),
.wp-block-cover p:not(.has-text-color),
.wp-block-cover h1:not(.has-text-color),
.wp-block-cover h2:not(.has-text-color),
.wp-block-cover h3:not(.has-text-color),
.wp-block-cover h4:not(.has-text-color),
.wp-block-cover h5:not(.has-text-color),
.wp-block-cover h6:not(.has-text-color),
.wp-block-cover .wp-block-subhead:not(.has-text-color) {
  color: inherit; }

.wp-block-cover__video-background {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: cover; }

section.wp-block-cover-image h2,
.wp-block-cover-image-text,
.wp-block-cover-text {
  color: #fff; }

section.wp-block-cover-image h2 a,
section.wp-block-cover-image h2 a:hover,
section.wp-block-cover-image h2 a:focus,
section.wp-block-cover-image h2 a:active,
.wp-block-cover-image-text a,
.wp-block-cover-image-text a:hover,
.wp-block-cover-image-text a:focus,
.wp-block-cover-image-text a:active,
.wp-block-cover-text a,
.wp-block-cover-text a:hover,
.wp-block-cover-text a:focus,
.wp-block-cover-text a:active {
  color: #fff; }

.wp-block-cover-image
.wp-block-cover.has-left-content {
  justify-content: flex-start; }

.wp-block-cover-image
.wp-block-cover.has-right-content {
  justify-content: flex-end; }

section.wp-block-cover-image.has-left-content > h2,
.wp-block-cover-image.has-left-content .wp-block-cover-image-text,
.wp-block-cover.has-left-content .wp-block-cover-text {
  margin-left: 0;
  text-align: left; }

section.wp-block-cover-image.has-right-content > h2,
.wp-block-cover-image.has-right-content .wp-block-cover-image-text,
.wp-block-cover.has-right-content .wp-block-cover-text {
  margin-right: 0;
  text-align: right; }

section.wp-block-cover-image > h2,
.wp-block-cover-image .wp-block-cover-image-text,
.wp-block-cover .wp-block-cover-text {
  font-size: 2em;
  line-height: 1.25;
  z-index: 1;
  margin-bottom: 0;
  max-width: 580px;
  padding: 14px;
  text-align: center; }

.block-editor-block-list__block[data-type="core/embed"][data-align="left"],
.block-editor-block-list__block[data-type="core/embed"][data-align="right"],
.wp-block-embed.alignleft,
.wp-block-embed.alignright {
  max-width: 360px;
  width: 100%; }

.wp-block-embed {
  margin-bottom: 1em; }

.wp-block-embed figcaption {
  margin-top: 0.5em;
  margin-bottom: 1em; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper {
  position: relative; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper::before {
  content: "";
  display: block;
  padding-top: 50%; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper::before {
  padding-top: 42.85%; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper::before {
  padding-top: 50%; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper::before {
  padding-top: 56.25%; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper::before {
  padding-top: 75%; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper::before {
  padding-top: 100%; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper::before {
  padding-top: 177.78%; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper::before {
  padding-top: 200%; }

.wp-block-file {
  margin-bottom: 1.5em; }

.wp-block-file.aligncenter {
  text-align: center; }

.wp-block-file.alignright {
  /*rtl:ignore*/
  text-align: right; }

.wp-block-file .wp-block-file__button {
  background: #32373c;
  border-radius: 2em;
  color: #fff;
  font-size: 13px;
  padding: 0.5em 1em; }

.wp-block-file a.wp-block-file__button {
  text-decoration: none; }

.wp-block-file a.wp-block-file__button:hover, .wp-block-file a.wp-block-file__button:visited, .wp-block-file a.wp-block-file__button:focus, .wp-block-file a.wp-block-file__button:active {
  box-shadow: none;
  color: #fff;
  opacity: 0.85;
  text-decoration: none; }

.wp-block-file * + .wp-block-file__button {
  margin-left: 0.75em; }

.wp-block-gallery,
.blocks-gallery-grid {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0; }

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item,
.blocks-gallery-grid .blocks-gallery-image,
.blocks-gallery-grid .blocks-gallery-item {
  margin: 0 16px 16px 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  position: relative; }

.wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure,
.blocks-gallery-grid .blocks-gallery-image figure,
.blocks-gallery-grid .blocks-gallery-item figure {
  margin: 0;
  height: 100%; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .wp-block-gallery .blocks-gallery-image figure,
  .wp-block-gallery .blocks-gallery-item figure,
  .blocks-gallery-grid .blocks-gallery-image figure,
  .blocks-gallery-grid .blocks-gallery-item figure {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start; } }

.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img,
.blocks-gallery-grid .blocks-gallery-image img,
.blocks-gallery-grid .blocks-gallery-item img {
  display: block;
  max-width: 100%;
  height: auto; }

.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img,
.blocks-gallery-grid .blocks-gallery-image img,
.blocks-gallery-grid .blocks-gallery-item img {
  width: 100%; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .wp-block-gallery .blocks-gallery-image img,
  .wp-block-gallery .blocks-gallery-item img,
  .blocks-gallery-grid .blocks-gallery-image img,
  .blocks-gallery-grid .blocks-gallery-item img {
    width: auto; } }

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption,
.blocks-gallery-grid .blocks-gallery-image figcaption,
.blocks-gallery-grid .blocks-gallery-item figcaption {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 40px 10px 9px;
  color: #fff;
  text-align: center;
  font-size: 13px;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, rgba(0, 0, 0, 0.7)), color-stop(70%, rgba(0, 0, 0, 0.3)), to(transparent));
  background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.7) 0, rgba(0, 0, 0, 0.3) 70%, transparent);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0, rgba(0, 0, 0, 0.3) 70%, transparent); }

.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img,
.blocks-gallery-grid .blocks-gallery-image figcaption img,
.blocks-gallery-grid .blocks-gallery-item figcaption img {
  display: inline; }

.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img,
.blocks-gallery-grid.is-cropped .blocks-gallery-image a,
.blocks-gallery-grid.is-cropped .blocks-gallery-image img,
.blocks-gallery-grid.is-cropped .blocks-gallery-item a,
.blocks-gallery-grid.is-cropped .blocks-gallery-item img {
  width: 100%; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .wp-block-gallery.is-cropped .blocks-gallery-image a,
  .wp-block-gallery.is-cropped .blocks-gallery-image img,
  .wp-block-gallery.is-cropped .blocks-gallery-item a,
  .wp-block-gallery.is-cropped .blocks-gallery-item img,
  .blocks-gallery-grid.is-cropped .blocks-gallery-image a,
  .blocks-gallery-grid.is-cropped .blocks-gallery-image img,
  .blocks-gallery-grid.is-cropped .blocks-gallery-item a,
  .blocks-gallery-grid.is-cropped .blocks-gallery-item img {
    height: 100%;
    flex: 1 1;
    object-fit: cover; } }

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item,
.blocks-gallery-grid .blocks-gallery-image,
.blocks-gallery-grid .blocks-gallery-item {
  width: calc((100% - 16px) / 2); }

.wp-block-gallery .blocks-gallery-image:nth-of-type(even),
.wp-block-gallery .blocks-gallery-item:nth-of-type(even),
.blocks-gallery-grid .blocks-gallery-image:nth-of-type(even),
.blocks-gallery-grid .blocks-gallery-item:nth-of-type(even) {
  margin-right: 0; }

.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item,
.blocks-gallery-grid.columns-1 .blocks-gallery-image,
.blocks-gallery-grid.columns-1 .blocks-gallery-item {
  width: 100%;
  margin-right: 0; }

@media (min-width: 600px) {
  .wp-block-gallery.columns-3 .blocks-gallery-image,
  .wp-block-gallery.columns-3 .blocks-gallery-item,
  .blocks-gallery-grid.columns-3 .blocks-gallery-image,
  .blocks-gallery-grid.columns-3 .blocks-gallery-item {
    width: calc((100% - 16px * 2) / 3);
    margin-right: 16px; }
  @supports (-ms-ime-align: auto) {
    .wp-block-gallery.columns-3 .blocks-gallery-image,
    .wp-block-gallery.columns-3 .blocks-gallery-item,
    .blocks-gallery-grid.columns-3 .blocks-gallery-image,
    .blocks-gallery-grid.columns-3 .blocks-gallery-item {
      width: calc((100% - 16px * 2) / 3 - 1px); } }
  .wp-block-gallery.columns-4 .blocks-gallery-image,
  .wp-block-gallery.columns-4 .blocks-gallery-item,
  .blocks-gallery-grid.columns-4 .blocks-gallery-image,
  .blocks-gallery-grid.columns-4 .blocks-gallery-item {
    width: calc((100% - 16px * 3) / 4);
    margin-right: 16px; }
  @supports (-ms-ime-align: auto) {
    .wp-block-gallery.columns-4 .blocks-gallery-image,
    .wp-block-gallery.columns-4 .blocks-gallery-item,
    .blocks-gallery-grid.columns-4 .blocks-gallery-image,
    .blocks-gallery-grid.columns-4 .blocks-gallery-item {
      width: calc((100% - 16px * 3) / 4 - 1px); } }
  .wp-block-gallery.columns-5 .blocks-gallery-image,
  .wp-block-gallery.columns-5 .blocks-gallery-item,
  .blocks-gallery-grid.columns-5 .blocks-gallery-image,
  .blocks-gallery-grid.columns-5 .blocks-gallery-item {
    width: calc((100% - 16px * 4) / 5);
    margin-right: 16px; }
  @supports (-ms-ime-align: auto) {
    .wp-block-gallery.columns-5 .blocks-gallery-image,
    .wp-block-gallery.columns-5 .blocks-gallery-item,
    .blocks-gallery-grid.columns-5 .blocks-gallery-image,
    .blocks-gallery-grid.columns-5 .blocks-gallery-item {
      width: calc((100% - 16px * 4) / 5 - 1px); } }
  .wp-block-gallery.columns-6 .blocks-gallery-image,
  .wp-block-gallery.columns-6 .blocks-gallery-item,
  .blocks-gallery-grid.columns-6 .blocks-gallery-image,
  .blocks-gallery-grid.columns-6 .blocks-gallery-item {
    width: calc((100% - 16px * 5) / 6);
    margin-right: 16px; }
  @supports (-ms-ime-align: auto) {
    .wp-block-gallery.columns-6 .blocks-gallery-image,
    .wp-block-gallery.columns-6 .blocks-gallery-item,
    .blocks-gallery-grid.columns-6 .blocks-gallery-image,
    .blocks-gallery-grid.columns-6 .blocks-gallery-item {
      width: calc((100% - 16px * 5) / 6 - 1px); } }
  .wp-block-gallery.columns-7 .blocks-gallery-image,
  .wp-block-gallery.columns-7 .blocks-gallery-item,
  .blocks-gallery-grid.columns-7 .blocks-gallery-image,
  .blocks-gallery-grid.columns-7 .blocks-gallery-item {
    width: calc((100% - 16px * 6) / 7);
    margin-right: 16px; }
  @supports (-ms-ime-align: auto) {
    .wp-block-gallery.columns-7 .blocks-gallery-image,
    .wp-block-gallery.columns-7 .blocks-gallery-item,
    .blocks-gallery-grid.columns-7 .blocks-gallery-image,
    .blocks-gallery-grid.columns-7 .blocks-gallery-item {
      width: calc((100% - 16px * 6) / 7 - 1px); } }
  .wp-block-gallery.columns-8 .blocks-gallery-image,
  .wp-block-gallery.columns-8 .blocks-gallery-item,
  .blocks-gallery-grid.columns-8 .blocks-gallery-image,
  .blocks-gallery-grid.columns-8 .blocks-gallery-item {
    width: calc((100% - 16px * 7) / 8);
    margin-right: 16px; }
  @supports (-ms-ime-align: auto) {
    .wp-block-gallery.columns-8 .blocks-gallery-image,
    .wp-block-gallery.columns-8 .blocks-gallery-item,
    .blocks-gallery-grid.columns-8 .blocks-gallery-image,
    .blocks-gallery-grid.columns-8 .blocks-gallery-item {
      width: calc((100% - 16px * 7) / 8 - 1px); } }
  .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
  .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
  .blocks-gallery-grid.columns-1 .blocks-gallery-image:nth-of-type(1n),
  .blocks-gallery-grid.columns-1 .blocks-gallery-item:nth-of-type(1n) {
    margin-right: 0; }
  .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
  .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
  .blocks-gallery-grid.columns-2 .blocks-gallery-image:nth-of-type(2n),
  .blocks-gallery-grid.columns-2 .blocks-gallery-item:nth-of-type(2n) {
    margin-right: 0; }
  .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
  .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
  .blocks-gallery-grid.columns-3 .blocks-gallery-image:nth-of-type(3n),
  .blocks-gallery-grid.columns-3 .blocks-gallery-item:nth-of-type(3n) {
    margin-right: 0; }
  .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
  .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
  .blocks-gallery-grid.columns-4 .blocks-gallery-image:nth-of-type(4n),
  .blocks-gallery-grid.columns-4 .blocks-gallery-item:nth-of-type(4n) {
    margin-right: 0; }
  .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
  .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
  .blocks-gallery-grid.columns-5 .blocks-gallery-image:nth-of-type(5n),
  .blocks-gallery-grid.columns-5 .blocks-gallery-item:nth-of-type(5n) {
    margin-right: 0; }
  .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
  .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
  .blocks-gallery-grid.columns-6 .blocks-gallery-image:nth-of-type(6n),
  .blocks-gallery-grid.columns-6 .blocks-gallery-item:nth-of-type(6n) {
    margin-right: 0; }
  .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
  .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
  .blocks-gallery-grid.columns-7 .blocks-gallery-image:nth-of-type(7n),
  .blocks-gallery-grid.columns-7 .blocks-gallery-item:nth-of-type(7n) {
    margin-right: 0; }
  .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
  .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n),
  .blocks-gallery-grid.columns-8 .blocks-gallery-image:nth-of-type(8n),
  .blocks-gallery-grid.columns-8 .blocks-gallery-item:nth-of-type(8n) {
    margin-right: 0; } }

.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child,
.blocks-gallery-grid .blocks-gallery-image:last-child,
.blocks-gallery-grid .blocks-gallery-item:last-child {
  margin-right: 0; }

.wp-block-gallery.alignleft, .wp-block-gallery.alignright,
.blocks-gallery-grid.alignleft,
.blocks-gallery-grid.alignright {
  max-width: 290px;
  width: 100%; }

.wp-block-gallery.aligncenter .blocks-gallery-item figure,
.blocks-gallery-grid.aligncenter .blocks-gallery-item figure {
  justify-content: center; }

.wp-block-image {
  margin-bottom: 1em; }

.wp-block-image img {
  max-width: 100%; }

.wp-block-image.aligncenter {
  text-align: center; }

.wp-block-image.alignfull img,
.wp-block-image.alignwide img {
  width: 100%; }

.wp-block-image .alignleft,
.wp-block-image .alignright,
.wp-block-image .aligncenter, .wp-block-image.is-resized {
  display: table;
  margin-left: 0;
  margin-right: 0; }

.wp-block-image .alignleft > figcaption,
.wp-block-image .alignright > figcaption,
.wp-block-image .aligncenter > figcaption, .wp-block-image.is-resized > figcaption {
  display: table-caption;
  caption-side: bottom; }

.wp-block-image .alignleft {
  /*rtl:ignore*/
  float: left;
  /*rtl:ignore*/
  margin-right: 1em; }

.wp-block-image .alignright {
  /*rtl:ignore*/
  float: right;
  /*rtl:ignore*/
  margin-left: 1em; }

.wp-block-image .aligncenter {
  margin-left: auto;
  margin-right: auto; }

.wp-block-image figcaption {
  margin-top: 0.5em;
  margin-bottom: 1em; }

.is-style-rounded img {
  border-radius: 9999px; }

.is-style-circle-mask img {
  border-radius: 9999px; }

@supports ((-webkit-mask-image: none) or (mask-image: none)) or (-webkit-mask-image: none) {
  .is-style-circle-mask img {
    /* stylelint-disable */
    -webkit-mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
    mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
    /* stylelint-enable */
    mask-mode: alpha;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-position: center;
    mask-position: center;
    border-radius: 0; } }

.wp-block-latest-comments__comment {
  font-size: 15px;
  line-height: 1.1;
  list-style: none;
  margin-bottom: 1em; }

.has-avatars .wp-block-latest-comments__comment {
  min-height: 36px;
  list-style: none; }

.has-avatars .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta,
.has-avatars .wp-block-latest-comments__comment .wp-block-latest-comments__comment-excerpt {
  margin-left: 52px; }

.has-dates .wp-block-latest-comments__comment,
.has-excerpts .wp-block-latest-comments__comment {
  line-height: 1.5; }

.wp-block-latest-comments__comment-excerpt p {
  font-size: 14px;
  line-height: 1.8;
  margin: 5px 0 20px; }

.wp-block-latest-comments__comment-date {
  color: #8f98a1;
  display: block;
  font-size: 12px; }

.wp-block-latest-comments .avatar,
.wp-block-latest-comments__comment-avatar {
  border-radius: 24px;
  display: block;
  float: left;
  height: 40px;
  margin-right: 12px;
  width: 40px; }

.wp-block-latest-posts.alignleft {
  /*rtl:ignore*/
  margin-right: 2em; }

.wp-block-latest-posts.alignright {
  /*rtl:ignore*/
  margin-left: 2em; }

.wp-block-latest-posts.wp-block-latest-posts__list {
  list-style: none; }

.wp-block-latest-posts.wp-block-latest-posts__list li {
  clear: both; }

.wp-block-latest-posts.is-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0; }

.wp-block-latest-posts.is-grid li {
  margin: 0 16px 16px 0;
  width: 100%; }

@media (min-width: 600px) {
  .wp-block-latest-posts.columns-2 li {
    width: calc((100% / 2) - 16px); }
  .wp-block-latest-posts.columns-3 li {
    width: calc((100% / 3) - 16px); }
  .wp-block-latest-posts.columns-4 li {
    width: calc((100% / 4) - 16px); }
  .wp-block-latest-posts.columns-5 li {
    width: calc((100% / 5) - 16px); }
  .wp-block-latest-posts.columns-6 li {
    width: calc((100% / 6) - 16px); } }

.wp-block-latest-posts__post-date {
  display: block;
  color: #6c7781;
  font-size: 13px; }

.wp-block-latest-posts__post-excerpt {
  margin-top: 8px;
  margin-bottom: 16px; }

.wp-block-latest-posts__featured-image img {
  height: auto;
  width: auto; }

.wp-block-latest-posts__featured-image.alignleft {
  /*rtl:ignore*/
  margin-right: 1em; }

.wp-block-latest-posts__featured-image.alignright {
  /*rtl:ignore*/
  margin-left: 1em; }

.wp-block-latest-posts__featured-image.aligncenter {
  margin-bottom: 1em;
  text-align: center; }

.wp-block-media-text {
  /*!rtl:begin:ignore*/
  direction: ltr;
  /*!rtl:end:ignore*/
  display: grid;
  grid-template-columns: 50% 1fr;
  grid-template-rows: auto; }

.wp-block-media-text.has-media-on-the-right {
  grid-template-columns: 1fr 50%; }

.wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__media {
  align-self: start; }

.wp-block-media-text .wp-block-media-text__content,
.wp-block-media-text .wp-block-media-text__media,
.wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__media {
  align-self: center; }

.wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__media {
  align-self: end; }

.wp-block-media-text .wp-block-media-text__media {
  /*!rtl:begin:ignore*/
  grid-column: 1;
  grid-row: 1;
  /*!rtl:end:ignore*/
  margin: 0; }

.wp-block-media-text .wp-block-media-text__content {
  direction: ltr;
  /*!rtl:begin:ignore*/
  grid-column: 2;
  grid-row: 1;
  /*!rtl:end:ignore*/
  padding: 0 8% 0 8%;
  word-break: break-word; }

.wp-block-media-text.has-media-on-the-right .wp-block-media-text__media {
  /*!rtl:begin:ignore*/
  grid-column: 2;
  grid-row: 1;
  /*!rtl:end:ignore*/ }

.wp-block-media-text.has-media-on-the-right .wp-block-media-text__content {
  /*!rtl:begin:ignore*/
  grid-column: 1;
  grid-row: 1;
  /*!rtl:end:ignore*/ }

.wp-block-media-text > figure > img,
.wp-block-media-text > figure > video {
  max-width: unset;
  width: 100%;
  vertical-align: middle; }

.wp-block-media-text.is-image-fill figure.wp-block-media-text__media {
  height: 100%;
  min-height: 250px;
  background-size: cover; }

.wp-block-media-text.is-image-fill figure.wp-block-media-text__media > img {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

/*
  * Here we here not able to use a mobile first CSS approach.
  * Custom widths are set using inline styles, and on mobile,
  * we need 100% width, so we use important to overwrite the inline style.
  * If the style were set on mobile first, on desktop styles,
  * we would have no way of setting the style again to the inline style.
  */
@media (max-width: 600px) {
  .wp-block-media-text.is-stacked-on-mobile {
    grid-template-columns: 100% !important; }
  .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__media {
    grid-column: 1;
    grid-row: 1; }
  .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__content {
    grid-column: 1;
    grid-row: 2; }
  .wp-block-media-text.is-stacked-on-mobile.has-media-on-the-right .wp-block-media-text__media {
    grid-column: 1;
    grid-row: 2; }
  .wp-block-media-text.is-stacked-on-mobile.has-media-on-the-right .wp-block-media-text__content {
    grid-column: 1;
    grid-row: 1; } }

/*
  * Frontend: reset the default list styles
  */
.wp-block-navigation > ul {
  display: block;
  list-style: none;
  margin: 0;
  padding-left: 0; }

@media (min-width: 600px) {
  .wp-block-navigation > ul {
    display: flex;
    flex-wrap: wrap; } }

.wp-block-navigation > ul ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-left: 0; }

.wp-block-navigation > ul ul li {
  margin: 0; }

/*
  * Frontend: styles for submenu flyout
  */
.wp-block-navigation > ul li {
  z-index: 1; }

.wp-block-navigation > ul li:hover, .wp-block-navigation > ul li:focus-within {
  cursor: pointer;
  z-index: 99999; }

.wp-block-navigation > ul li:hover > ul,
.wp-block-navigation > ul li:focus-within > ul,
.wp-block-navigation > ul li ul:hover,
.wp-block-navigation > ul li ul:focus {
  visibility: visible;
  opacity: 1;
  display: flex;
  flex-direction: column; }

.wp-block-navigation > ul > li ul {
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 200px;
  max-width: 200px;
  opacity: 0;
  -webkit-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear;
  visibility: hidden; }

/*
  * Styles shared between editor and frontend
  */
.wp-block-navigation,
.wp-block-navigation .block-editor-block-list__layout {
  display: flex;
  flex-wrap: wrap; }

.wp-block-navigation .block-editor-block-list__layout .block-editor-block-list__layout {
  width: 200px; }

.wp-block-navigation .block-editor-inner-blocks > .block-editor-block-list__layout > .wp-block {
  margin: 0;
  width: auto; }

.wp-block-navigation,
.wp-block-navigation > .wp-block-navigation__container {
  align-items: center;
  width: 100%; }

.wp-block-navigation > .wp-block-navigation-link,
.wp-block-navigation > .wp-block-navigation__container > .wp-block-navigation-link {
  display: flex;
  margin-top: 0;
  margin-bottom: 0; }

.wp-block-navigation .wp-block-navigation-link {
  position: relative;
  margin: 0;
  min-height: 56px;
  display: flex;
  line-height: 1.4; }

.wp-block-navigation .wp-block-navigation-link .wp-block,
.wp-block-navigation .wp-block-navigation-link .wp-block-navigation-link {
  min-height: auto;
  padding: 0; }

.wp-block-navigation .wp-block-navigation-link .wp-block .wp-block-navigation-link {
  margin: 0; }

.wp-block-navigation .wp-block-navigation-link > .block-editor-inner-blocks {
  display: none; }

.wp-block-navigation .wp-block-navigation-link.has-child > .wp-block-navigation__container,
.wp-block-navigation .wp-block-navigation-link.is-editing.has-child > .block-editor-inner-blocks {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.15);
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0; }

.wp-block-navigation .wp-block-navigation-link.has-child > .wp-block-navigation__container .block-editor-inner-blocks,
.wp-block-navigation .wp-block-navigation-link.has-child > .wp-block-navigation__container .wp-block-navigation__container,
.wp-block-navigation .wp-block-navigation-link.is-editing.has-child > .block-editor-inner-blocks .block-editor-inner-blocks,
.wp-block-navigation .wp-block-navigation-link.is-editing.has-child > .block-editor-inner-blocks .wp-block-navigation__container {
  left: 100%;
  top: -1px; }

.wp-block-navigation .wp-block-navigation-link .block-editor-inner-blocks,
.wp-block-navigation .wp-block-navigation-link .wp-block-navigation__container {
  background-color: inherit;
  color: inherit; }

.wp-block-navigation .wp-block-navigation-link .wp-block-navigation-link__content {
  display: flex;
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 6px 16px; }

.wp-block-navigation .wp-block-navigation-link .wp-block-navigation-link:first-child:not(:only-child) .wp-block-navigation-link__content {
  padding-top: 8px; }

.wp-block-navigation .wp-block-navigation-link .wp-block-navigation-link:last-child .wp-block-navigation-link__content {
  padding-bottom: 8px; }

.wp-block-navigation .wp-block-navigation-link.has-child .wp-block-navigation-link__content {
  min-width: 100%;
  padding-right: 32px;
  position: relative; }

.wp-block-navigation .wp-block-navigation-link .wp-block-navigation-link__submenu-icon {
  position: absolute;
  right: 16px; }

.wp-block-navigation .wp-block-navigation-link .wp-block-navigation-link__submenu-icon svg {
  fill: currentColor; }

.wp-block-navigation .wp-block-navigation-link .wp-block-navigation-link svg {
  -webkit-transform: rotate(0);
          transform: rotate(0); }

.wp-block-navigation .wp-block-navigation-link.has-text-color .wp-block-navigation-link__content {
  color: inherit; }

.wp-block-navigation .wp-block-navigation-link:not(.has-text-color) > .block-editor-inner-blocks,
.wp-block-navigation .wp-block-navigation-link:not(.has-text-color) > .wp-block-navigation__container,
.wp-block-navigation.is-style-light .wp-block-navigation-link:not(.has-text-color) > .block-editor-inner-blocks,
.wp-block-navigation.is-style-light .wp-block-navigation-link:not(.has-text-color) > .wp-block-navigation__container {
  color: #111; }

.wp-block-navigation .wp-block-navigation-link:not(.has-background) > .block-editor-inner-blocks,
.wp-block-navigation .wp-block-navigation-link:not(.has-background) > .wp-block-navigation__container,
.wp-block-navigation.is-style-light .wp-block-navigation-link:not(.has-background) > .block-editor-inner-blocks,
.wp-block-navigation.is-style-light .wp-block-navigation-link:not(.has-background) > .wp-block-navigation__container {
  background-color: #fff; }

.wp-block-navigation.is-style-dark .wp-block-navigation-link:not(.has-text-color) > .block-editor-inner-blocks,
.wp-block-navigation.is-style-dark .wp-block-navigation-link:not(.has-text-color) > .wp-block-navigation__container {
  color: #fff; }

.wp-block-navigation.is-style-dark .wp-block-navigation-link:not(.has-background) > .block-editor-inner-blocks,
.wp-block-navigation.is-style-dark .wp-block-navigation-link:not(.has-background) > .wp-block-navigation__container {
  background-color: #333; }

/*
  * Frontend: non-shared styles & overrides
  */
.wp-block-navigation .wp-block-navigation-link.has-child > .wp-block-navigation__container {
  display: flex;
  flex-direction: column;
  padding: 0; }

/*
  * TODO: organize/untangle styles below this line
  */
.wp-block-navigation > ul > li > a {
  display: flex;
  align-items: center; }

.wp-block-navigation > ul > li:first-of-type > a {
  padding-left: 0; }

.wp-block-navigation > ul > li:last-of-type > a {
  padding-right: 0; }

.wp-block-navigation.items-justified-left > ul {
  justify-content: flex-start; }

.wp-block-navigation.items-justified-center > ul {
  justify-content: center; }

.wp-block-navigation.items-justified-right > ul {
  justify-content: flex-end; }

.is-small-text {
  font-size: 14px; }

.is-regular-text {
  font-size: 16px; }

.is-large-text {
  font-size: 36px; }

.is-larger-text {
  font-size: 48px; }

.has-drop-cap:not(:focus)::first-letter {
  float: left;
  font-size: 8.4em;
  line-height: 0.68;
  font-weight: 100;
  margin: 0.05em 0.1em 0 0;
  text-transform: uppercase;
  font-style: normal; }

p.has-background {
  padding: 20px 30px; }

p.has-text-color a {
  color: inherit; }

.wp-block-pullquote {
  padding: 3em 0;
  margin-left: 0;
  margin-right: 0;
  text-align: center; }

.wp-block-pullquote.alignleft, .wp-block-pullquote.alignright {
  max-width: 290px; }

.wp-block-pullquote.alignleft p, .wp-block-pullquote.alignright p {
  font-size: 20px; }

.wp-block-pullquote p {
  font-size: 28px;
  line-height: 1.6; }

.wp-block-pullquote cite,
.wp-block-pullquote footer {
  position: relative; }

.wp-block-pullquote .has-text-color a {
  color: inherit; }

.wp-block-pullquote:not(.is-style-solid-color) {
  background: none; }

.wp-block-pullquote.is-style-solid-color {
  border: none; }

.wp-block-pullquote.is-style-solid-color blockquote {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  max-width: 60%; }

.wp-block-pullquote.is-style-solid-color blockquote p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 32px; }

.wp-block-pullquote.is-style-solid-color blockquote cite {
  text-transform: none;
  font-style: normal; }

.wp-block-pullquote cite {
  color: inherit; }

.wp-block-quote.is-style-large, .wp-block-quote.is-large {
  margin: 0 0 16px;
  padding: 0 1em; }

.wp-block-quote.is-style-large p, .wp-block-quote.is-large p {
  font-size: 24px;
  font-style: italic;
  line-height: 1.6; }

.wp-block-quote.is-style-large cite,
.wp-block-quote.is-style-large footer, .wp-block-quote.is-large cite,
.wp-block-quote.is-large footer {
  font-size: 18px;
  text-align: right; }

.wp-block-rss.alignleft {
  /*rtl:ignore*/
  margin-right: 2em; }

.wp-block-rss.alignright {
  /*rtl:ignore*/
  margin-left: 2em; }

.wp-block-rss.is-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none; }

.wp-block-rss.is-grid li {
  margin: 0 16px 16px 0;
  width: 100%; }

@media (min-width: 600px) {
  .wp-block-rss.columns-2 li {
    width: calc(( 100% / 2 ) - 16px); }
  .wp-block-rss.columns-3 li {
    width: calc(( 100% / 3 ) - 16px); }
  .wp-block-rss.columns-4 li {
    width: calc(( 100% / 4 ) - 16px); }
  .wp-block-rss.columns-5 li {
    width: calc(( 100% / 5 ) - 16px); }
  .wp-block-rss.columns-6 li {
    width: calc(( 100% / 6 ) - 16px); } }

.wp-block-rss__item-publish-date,
.wp-block-rss__item-author {
  display: block;
  color: #6c7781;
  font-size: 13px; }

.wp-block-search {
  display: flex;
  flex-wrap: wrap; }

.wp-block-search .wp-block-search__label {
  width: 100%; }

.wp-block-search .wp-block-search__input {
  flex-grow: 1;
  max-width: 360px; }

.wp-block-search .wp-block-search__button {
  margin-left: 10px; }

.wp-block-separator.is-style-wide {
  border-bottom-width: 1px; }

.wp-block-separator.is-style-dots {
  background: none !important;
  border: none;
  text-align: center;
  max-width: none;
  line-height: 1;
  height: auto; }

.wp-block-separator.is-style-dots::before {
  content: "\00b7 \00b7 \00b7";
  color: currentColor;
  font-size: 20px;
  letter-spacing: 2em;
  padding-left: 2em;
  font-family: serif; }

.wp-block-social-links {
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0; }

.wp-block-social-links .wp-social-link a,
.wp-block-social-links .wp-social-link a:hover {
  text-decoration: none;
  border-bottom: 0;
  box-shadow: none; }

.wp-social-link {
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  margin-right: 8px;
  -webkit-transition: -webkit-transform 0.1s ease;
  transition: -webkit-transform 0.1s ease;
  transition: transform 0.1s ease;
  transition: transform 0.1s ease, -webkit-transform 0.1s ease; }

@media (prefers-reduced-motion: reduce) {
  .wp-social-link {
    -webkit-transition-duration: 0s;
            transition-duration: 0s; } }

.wp-social-link a {
  padding: 6px;
  display: block;
  line-height: 0;
  -webkit-transition: -webkit-transform 0.1s ease;
  transition: -webkit-transform 0.1s ease;
  transition: transform 0.1s ease;
  transition: transform 0.1s ease, -webkit-transform 0.1s ease; }

.wp-social-link a,
.wp-social-link a:hover,
.wp-social-link a:active,
.wp-social-link a:visited,
.wp-social-link svg {
  color: currentColor;
  fill: currentColor; }

.wp-social-link:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

.wp-block-social-links.aligncenter {
  justify-content: center;
  display: flex; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link {
  background-color: #f0f0f0;
  color: #444; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-amazon {
  background-color: #f90;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-bandcamp {
  background-color: #1ea0c3;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-behance {
  background-color: #0757fe;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-codepen {
  background-color: #1e1f26;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-deviantart {
  background-color: #02e49b;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-dribbble {
  background-color: #e94c89;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-dropbox {
  background-color: #4280ff;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-etsy {
  background-color: #f45800;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-facebook {
  background-color: #1977f2;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-fivehundredpx {
  background-color: #000;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-flickr {
  background-color: #0461dd;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-foursquare {
  background-color: #e65678;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-github {
  background-color: #24292d;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-goodreads {
  background-color: #eceadd;
  color: #382110; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-google {
  background-color: #ea4434;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-instagram {
  background-color: #f00075;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-lastfm {
  background-color: #e21b24;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-linkedin {
  background-color: #0577b5;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-mastodon {
  background-color: #3288d4;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-medium {
  background-color: #02ab6c;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-meetup {
  background-color: #f6405f;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-pinterest {
  background-color: #e60122;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-pocket {
  background-color: #ef4155;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-reddit {
  background-color: #fe4500;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-skype {
  background-color: #0478d7;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-snapchat {
  background-color: #fefc00;
  color: #fff;
  stroke: #000; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-soundcloud {
  background-color: #ff5600;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-spotify {
  background-color: #1bd760;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-tumblr {
  background-color: #011835;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-twitch {
  background-color: #6440a4;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-twitter {
  background-color: #21a1f3;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-vimeo {
  background-color: #1eb7ea;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-vk {
  background-color: #4680c2;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-wordpress {
  background-color: #3499cd;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-yelp {
  background-color: #d32422;
  color: #fff; }

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-youtube {
  background-color: #ff0100;
  color: #fff; }

.wp-block-social-links.is-style-logos-only .wp-social-link {
  background: none;
  padding: 4px; }

.wp-block-social-links.is-style-logos-only .wp-social-link svg {
  width: 28px;
  height: 28px; }

.wp-block-social-links.is-style-logos-only .wp-social-link-amazon {
  color: #f90; }

.wp-block-social-links.is-style-logos-only .wp-social-link-bandcamp {
  color: #1ea0c3; }

.wp-block-social-links.is-style-logos-only .wp-social-link-behance {
  color: #0757fe; }

.wp-block-social-links.is-style-logos-only .wp-social-link-codepen {
  color: #1e1f26; }

.wp-block-social-links.is-style-logos-only .wp-social-link-deviantart {
  color: #02e49b; }

.wp-block-social-links.is-style-logos-only .wp-social-link-dribbble {
  color: #e94c89; }

.wp-block-social-links.is-style-logos-only .wp-social-link-dropbox {
  color: #4280ff; }

.wp-block-social-links.is-style-logos-only .wp-social-link-etsy {
  color: #f45800; }

.wp-block-social-links.is-style-logos-only .wp-social-link-facebook {
  color: #1977f2; }

.wp-block-social-links.is-style-logos-only .wp-social-link-fivehundredpx {
  color: #000; }

.wp-block-social-links.is-style-logos-only .wp-social-link-flickr {
  color: #0461dd; }

.wp-block-social-links.is-style-logos-only .wp-social-link-foursquare {
  color: #e65678; }

.wp-block-social-links.is-style-logos-only .wp-social-link-github {
  color: #24292d; }

.wp-block-social-links.is-style-logos-only .wp-social-link-goodreads {
  color: #382110; }

.wp-block-social-links.is-style-logos-only .wp-social-link-google {
  color: #ea4434; }

.wp-block-social-links.is-style-logos-only .wp-social-link-instagram {
  color: #f00075; }

.wp-block-social-links.is-style-logos-only .wp-social-link-lastfm {
  color: #e21b24; }

.wp-block-social-links.is-style-logos-only .wp-social-link-linkedin {
  color: #0577b5; }

.wp-block-social-links.is-style-logos-only .wp-social-link-mastodon {
  color: #3288d4; }

.wp-block-social-links.is-style-logos-only .wp-social-link-medium {
  color: #02ab6c; }

.wp-block-social-links.is-style-logos-only .wp-social-link-meetup {
  color: #f6405f; }

.wp-block-social-links.is-style-logos-only .wp-social-link-pinterest {
  color: #e60122; }

.wp-block-social-links.is-style-logos-only .wp-social-link-pocket {
  color: #ef4155; }

.wp-block-social-links.is-style-logos-only .wp-social-link-reddit {
  color: #fe4500; }

.wp-block-social-links.is-style-logos-only .wp-social-link-skype {
  color: #0478d7; }

.wp-block-social-links.is-style-logos-only .wp-social-link-snapchat {
  color: #fff;
  stroke: #000; }

.wp-block-social-links.is-style-logos-only .wp-social-link-soundcloud {
  color: #ff5600; }

.wp-block-social-links.is-style-logos-only .wp-social-link-spotify {
  color: #1bd760; }

.wp-block-social-links.is-style-logos-only .wp-social-link-tumblr {
  color: #011835; }

.wp-block-social-links.is-style-logos-only .wp-social-link-twitch {
  color: #6440a4; }

.wp-block-social-links.is-style-logos-only .wp-social-link-twitter {
  color: #21a1f3; }

.wp-block-social-links.is-style-logos-only .wp-social-link-vimeo {
  color: #1eb7ea; }

.wp-block-social-links.is-style-logos-only .wp-social-link-vk {
  color: #4680c2; }

.wp-block-social-links.is-style-logos-only .wp-social-link-wordpress {
  color: #3499cd; }

.wp-block-social-links.is-style-logos-only .wp-social-link-yelp {
  background-color: #d32422;
  color: #fff; }

.wp-block-social-links.is-style-logos-only .wp-social-link-youtube {
  color: #ff0100; }

.wp-block-social-links.is-style-pill-shape .wp-social-link {
  width: auto; }

.wp-block-social-links.is-style-pill-shape .wp-social-link a {
  padding-left: 16px;
  padding-right: 16px; }

.wp-block-spacer {
  clear: both; }

p.wp-block-subhead {
  font-size: 1.1em;
  font-style: italic;
  opacity: 0.75; }

.wp-block-table {
  overflow-x: auto; }

.wp-block-table table {
  width: 100%; }

.wp-block-table .has-fixed-layout {
  table-layout: fixed;
  width: 100%; }

.wp-block-table .has-fixed-layout td,
.wp-block-table .has-fixed-layout th {
  word-break: break-word; }

.wp-block-table.alignleft, .wp-block-table.aligncenter, .wp-block-table.alignright {
  display: table;
  width: auto; }

.wp-block-table.alignleft td,
.wp-block-table.alignleft th, .wp-block-table.aligncenter td,
.wp-block-table.aligncenter th, .wp-block-table.alignright td,
.wp-block-table.alignright th {
  word-break: break-word; }

.wp-block-table .has-subtle-light-gray-background-color {
  background-color: #f3f4f5; }

.wp-block-table .has-subtle-pale-green-background-color {
  background-color: #e9fbe5; }

.wp-block-table .has-subtle-pale-blue-background-color {
  background-color: #e7f5fe; }

.wp-block-table .has-subtle-pale-pink-background-color {
  background-color: #fcf0ef; }

.wp-block-table.is-style-stripes {
  border-spacing: 0;
  border-collapse: inherit;
  background-color: transparent;
  border-bottom: 1px solid #f3f4f5; }

.wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
  background-color: #f3f4f5; }

.wp-block-table.is-style-stripes.has-subtle-light-gray-background-color tbody tr:nth-child(odd) {
  background-color: #f3f4f5; }

.wp-block-table.is-style-stripes.has-subtle-pale-green-background-color tbody tr:nth-child(odd) {
  background-color: #e9fbe5; }

.wp-block-table.is-style-stripes.has-subtle-pale-blue-background-color tbody tr:nth-child(odd) {
  background-color: #e7f5fe; }

.wp-block-table.is-style-stripes.has-subtle-pale-pink-background-color tbody tr:nth-child(odd) {
  background-color: #fcf0ef; }

.wp-block-table.is-style-stripes th,
.wp-block-table.is-style-stripes td {
  border-color: transparent; }

.wp-block-text-columns {
  display: flex; }

.wp-block-text-columns.aligncenter {
  display: flex; }

.wp-block-text-columns .wp-block-column {
  margin: 0 16px;
  padding: 0; }

.wp-block-text-columns .wp-block-column:first-child {
  margin-left: 0; }

.wp-block-text-columns .wp-block-column:last-child {
  margin-right: 0; }

.wp-block-text-columns.columns-2 .wp-block-column {
  width: calc(100% / 2); }

.wp-block-text-columns.columns-3 .wp-block-column {
  width: calc(100% / 3); }

.wp-block-text-columns.columns-4 .wp-block-column {
  width: calc(100% / 4); }

.wp-block-video {
  margin-left: 0;
  margin-right: 0; }

.wp-block-video video {
  max-width: 100%; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .wp-block-video [poster] {
    object-fit: cover; } }

.wp-block-video.aligncenter {
  text-align: center; }

.wp-block-video figcaption {
  margin-top: 0.5em;
  margin-bottom: 1em; }

:root {
  /* stylelint-disable function-comma-space-after */
  /* stylelint-enable function-comma-space-after */ }

:root .has-pale-pink-background-color {
  background-color: #f78da7; }

:root .has-vivid-red-background-color {
  background-color: #cf2e2e; }

:root .has-luminous-vivid-orange-background-color {
  background-color: #ff6900; }

:root .has-luminous-vivid-amber-background-color {
  background-color: #fcb900; }

:root .has-light-green-cyan-background-color {
  background-color: #7bdcb5; }

:root .has-vivid-green-cyan-background-color {
  background-color: #00d084; }

:root .has-pale-cyan-blue-background-color {
  background-color: #8ed1fc; }

:root .has-vivid-cyan-blue-background-color {
  background-color: #0693e3; }

:root .has-vivid-purple-background-color {
  background-color: #9b51e0; }

:root .has-very-light-gray-background-color {
  background-color: #eee; }

:root .has-cyan-bluish-gray-background-color {
  background-color: #abb8c3; }

:root .has-very-dark-gray-background-color {
  background-color: #313131; }

:root .has-pale-pink-color {
  color: #f78da7; }

:root .has-vivid-red-color {
  color: #cf2e2e; }

:root .has-luminous-vivid-orange-color {
  color: #ff6900; }

:root .has-luminous-vivid-amber-color {
  color: #fcb900; }

:root .has-light-green-cyan-color {
  color: #7bdcb5; }

:root .has-vivid-green-cyan-color {
  color: #00d084; }

:root .has-pale-cyan-blue-color {
  color: #8ed1fc; }

:root .has-vivid-cyan-blue-color {
  color: #0693e3; }

:root .has-vivid-purple-color {
  color: #9b51e0; }

:root .has-very-light-gray-color {
  color: #eee; }

:root .has-cyan-bluish-gray-color {
  color: #abb8c3; }

:root .has-very-dark-gray-color {
  color: #313131; }

:root .has-vivid-cyan-blue-to-vivid-purple-gradient-background {
  background: -webkit-linear-gradient(315deg, #0693e3 0%, #9b51e0 100%);
  background: linear-gradient(135deg, #0693e3 0%, #9b51e0 100%); }

:root .has-vivid-green-cyan-to-vivid-cyan-blue-gradient-background {
  background: -webkit-linear-gradient(315deg, #00d084 0%, #0693e3 100%);
  background: linear-gradient(135deg, #00d084 0%, #0693e3 100%); }

:root .has-light-green-cyan-to-vivid-green-cyan-gradient-background {
  background: -webkit-linear-gradient(315deg, #7adcb4 0%, #00d082 100%);
  background: linear-gradient(135deg, #7adcb4 0%, #00d082 100%); }

:root .has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
  background: -webkit-linear-gradient(315deg, #fcb900 0%, #ff6900 100%);
  background: linear-gradient(135deg, #fcb900 0%, #ff6900 100%); }

:root .has-luminous-vivid-orange-to-vivid-red-gradient-background {
  background: -webkit-linear-gradient(315deg, #ff6900 0%, #cf2e2e 100%);
  background: linear-gradient(135deg, #ff6900 0%, #cf2e2e 100%); }

:root .has-very-light-gray-to-cyan-bluish-gray-gradient-background {
  background: -webkit-linear-gradient(315deg, #eeeeee 0%, #a9b8c3 100%);
  background: linear-gradient(135deg, #eeeeee 0%, #a9b8c3 100%); }

:root .has-cool-to-warm-spectrum-gradient-background {
  background: -webkit-linear-gradient(315deg, #4aeadc 0%, #9778d1 20%, #cf2aba 40%, #ee2c82 60%, #fb6962 80%, #fef84c 100%);
  background: linear-gradient(135deg, #4aeadc 0%, #9778d1 20%, #cf2aba 40%, #ee2c82 60%, #fb6962 80%, #fef84c 100%); }

:root .has-blush-light-purple-gradient-background {
  background: -webkit-linear-gradient(315deg, #ffceec 0%, #9896f0 100%);
  background: linear-gradient(135deg, #ffceec 0%, #9896f0 100%); }

:root .has-blush-bordeaux-gradient-background {
  background: -webkit-linear-gradient(315deg, #fecda5 0%, #fe2d2d 50%, #6b003e 100%);
  background: linear-gradient(135deg, #fecda5 0%, #fe2d2d 50%, #6b003e 100%); }

:root .has-purple-crush-gradient-background {
  background: -webkit-linear-gradient(315deg, #34e2e4 0%, #4721fb 50%, #ab1dfe 100%);
  background: linear-gradient(135deg, #34e2e4 0%, #4721fb 50%, #ab1dfe 100%); }

:root .has-luminous-dusk-gradient-background {
  background: -webkit-linear-gradient(315deg, #ffcb70 0%, #c751c0 50%, #4158d0 100%);
  background: linear-gradient(135deg, #ffcb70 0%, #c751c0 50%, #4158d0 100%); }

:root .has-hazy-dawn-gradient-background {
  background: -webkit-linear-gradient(315deg, #faaca8 0%, #dad0ec 100%);
  background: linear-gradient(135deg, #faaca8 0%, #dad0ec 100%); }

:root .has-pale-ocean-gradient-background {
  background: -webkit-linear-gradient(315deg, #fff5cb 0%, #b6e3d4 50%, #33a7b5 100%);
  background: linear-gradient(135deg, #fff5cb 0%, #b6e3d4 50%, #33a7b5 100%); }

:root .has-electric-grass-gradient-background {
  background: -webkit-linear-gradient(315deg, #caf880 0%, #71ce7e 100%);
  background: linear-gradient(135deg, #caf880 0%, #71ce7e 100%); }

:root .has-subdued-olive-gradient-background {
  background: -webkit-linear-gradient(315deg, #fafae1 0%, #67a671 100%);
  background: linear-gradient(135deg, #fafae1 0%, #67a671 100%); }

:root .has-atomic-cream-gradient-background {
  background: -webkit-linear-gradient(315deg, #fdd79a 0%, #004a59 100%);
  background: linear-gradient(135deg, #fdd79a 0%, #004a59 100%); }

:root .has-nightshade-gradient-background {
  background: -webkit-linear-gradient(315deg, #330968 0%, #31cdcf 100%);
  background: linear-gradient(135deg, #330968 0%, #31cdcf 100%); }

:root .has-midnight-gradient-background {
  background: -webkit-linear-gradient(315deg, #020381 0%, #2874fc 100%);
  background: linear-gradient(135deg, #020381 0%, #2874fc 100%); }

.has-small-font-size {
  font-size: 13px; }

.has-regular-font-size,
.has-normal-font-size {
  font-size: 16px; }

.has-medium-font-size {
  font-size: 20px; }

.has-large-font-size {
  font-size: 36px; }

.has-larger-font-size,
.has-huge-font-size {
  font-size: 42px; }

.has-text-align-center {
  text-align: center; }

.has-text-align-left {
  /*rtl:ignore*/
  text-align: left; }

.has-text-align-right {
  /*rtl:ignore*/
  text-align: right; }

* {
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  color: #333;
  overflow-x: hidden;
  line-height: 1.5; }
  @media only screen and (min-width: 1400px) {
    body {
      font-size: 14px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    body {
      font-size: 12px; } }
  @media only screen and (min-width: 800px) and (max-width: 1200px) {
    body {
      font-size: 10px; } }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    body {
      font-size: 12px; } }

h1, h2, h3 {
  line-height: 1.2; }

.wrapper-central {
  position: relative;
  margin: auto;
  height: 100%; }
  @media only screen and (min-width: 1400px) {
    .wrapper-central {
      width: 1200px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .wrapper-central {
      width: 1000px; } }
  @media only screen and (min-width: 800px) and (max-width: 1200px) {
    .wrapper-central {
      width: 800px; } }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    .wrapper-central {
      width: 95%; } }

a {
  text-decoration: none;
  color: #008dc2; }

a:hover {
  color: #10396d; }

table.inPost_table {
  width: 90%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  border-collapse: collapse; }

table.inPost_table td {
  border: 1px solid #999;
  padding: 10px; }

table.inPost_table th {
  color: #008dc2;
  font-size: 1.2em; }

#site-post img {
  height: auto; }

.btn-box {
  margin: 3em 1em 3em 1em; }

.btn-box-center {
  margin: 3em 1em 3em 1em;
  text-align: center; }

#transition {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50; }

#transition + #transition {
  z-index: 52;
  position: absolute; }

#fondo-transition {
  background-color: #10396d;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 50;
  -webkit-transition: 1s height;
  transition: 1s height; }

.trans-exited #fondo-transition {
  height: 0;
  position: fixed; }

.trans-exit #fondo-transition {
  height: 100%;
  position: fixed; }

#plano {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 40px 0; }

#plano h1 {
  font-size: 2.5em;
  color: #333;
  font-weight: normal;
  margin-bottom: 20px;
  padding-top: 5px;
  border-top: 1px solid #333; }

#plano nav.menu ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #plano nav.menu ul {
      display: block; } }

#plano nav.menu ul li {
  width: 220px;
  height: 100px;
  margin: 10px;
  list-style: none;
  position: relative; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #plano nav.menu ul li {
      max-width: 90%;
      margin: 30px auto;
      height: auto; } }

#plano nav.menu ul li .image_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 100%;
  display: flex;
  align-content: center; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #plano nav.menu ul li .image_wrapper {
      position: relative; } }

#plano nav.menu ul li .image_wrapper img {
  max-width: 90%;
  max-height: 90%;
  margin: auto;
  vertical-align: middle; }
  @media only screen and (min-width: 400px) and (max-width: 800px), only screen and (max-width: 400px) {
    #plano nav.menu ul li .image_wrapper img {
      width: 90%;
      height: auto;
      max-height: none; } }

#plano nav.menu ul li span {
  display: none; }

.flex-container {
  display: flex; }
  @media only screen and (max-width: 640px) {
    .flex-container {
      flex-direction: column; } }

#plano-box {
  overflow-x: scroll;
  overflow-y: scroll; }

.plano-aside {
  margin-left: 1em;
  position: -webkit-sticky;
  position: sticky;
  align-self: flex-start;
  top: 70px; }

.search-box {
  padding: 1em 0.4em 1em 0.4em;
  background-color: white; }

#search-input {
  background-color: #eee;
  border: 1px solid #008dc2;
  border-radius: 10px;
  justify-content: center;
  padding: 0.3em;
  color: #555; }

.btn-group {
  margin: 3em;
  text-align: center;
  background-color: #eee;
  float: right;
  position: absolute;
  right: 250px; }

.btn-title {
  padding-bottom: 0.3em;
  background-color: #eee; }

#reset-btn {
  margin-left: 0.5em; }

.places-list {
  overflow-y: scroll;
  height: 500px; }

.places-list > ul {
  list-style-type: none;
  /* Remove bullets */
  padding: 0;
  /* Remove padding */
  margin: 0;
  /* Remove margins */ }

.places-list-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white; }

.places-list-title {
  padding: 1em;
  background-color: #10396d;
  color: white; }

.place-type-title {
  margin-left: 0.8em; }

.place-type-li {
  margin-left: 1.5em; }

.place-type-li:hover {
  cursor: pointer;
  color: white;
  background: #F57C00; }

.places-group {
  margin-top: 1em; }

path.place:hover {
  cursor: help; }

path.aula {
  fill: #ed3237; }

path.aula:hover {
  fill: #ff7f82ff; }

path.admin {
  fill: #d2d3d5; }

path.admin:hover {
  fill: #E1E1E3; }

path.aut {
  fill: #0071a1; }

path.aut:hover {
  fill: #008DC9; }

path.sala {
  fill: #00a859; }

path.sala:hover {
  fill: #00D170; }

path.dpto {
  fill: #ffcc29; }

path.dpto:hover {
  fill: #FFE37B; }

path.aux {
  fill: #ffa216; }

path.aux:hover {
  fill: #FFBC52; }

